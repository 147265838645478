import React, { Suspense, useState, useEffect } from 'react';
import { Form } from 'antd';

import { useTranslation } from 'react-i18next';

/**APP IMPORTS */
import { LizenzenHeader, LizenzenTable } from '@app_modules/Lizenzen/components/';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

function Lizenzen() {
  const [form] = Form.useForm();
  const [subscriptionStates, setsubscriptionState] = useState([]);

  return (
    <>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <LizenzenHeader setsubscriptionState={setsubscriptionState} form={form} />
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <LizenzenTable
          form={form}
          subscriptionStates={subscriptionStates}
          setsubscriptionState={setsubscriptionState}
        />
      </Suspense>
    </>
  );
}

export default Lizenzen;
