/**CORE IMPORTS */
import { GlobalController } from '@core_modules/Global/Controller';
import { ExactTitle } from '@core_common/components';
/** APP Imports */
import { PaymentHistoryController } from '@app_modules/PaymentHistory/controller';
import dayjs from 'dayjs';

const RecurringInvoiceController = ({ store, t, plan }) => {
  const computeTotal = (Subscriptions) => {
    let total = 0;
    const Lizenzen = foundLizenzen(Subscriptions?.license_id);

    if (Subscriptions?.payment_type === 1) {
      total = Subscriptions?.number * Lizenzen?.monatspreis + total;
    }
    if (Subscriptions?.payment_type === 2) {
      total = total + Subscriptions?.number * Lizenzen?.jahrespreis;
    }
    return total.toFixed(2);
  };
  const { _showresultMessage, getContactPerson } = GlobalController({ t });
  const { foundLizenzen } = PaymentHistoryController({ t, store });

  const getRecurringInvoice = async () => {
    const page = 1;
    const search = store.recurringInvoiceUtilities.search;
    const props = store.recurringInvoiceUtilities.props;
    let params = { page, search, props };

    const [data, error] = await store.recurringInvoices.LIST(params);
  };

  const handleChangeStatus = async (params) => {
    let { record, active } = params;

    let [successMessage, error] = await store.recurringInvoices.UPDATE(record.id, { active });

    await _showresultMessage(error, {
      successMessage
    });
  };

  const toggleViewDetailsModal = (record) => {
    const openViewDetailsModal = record;
    if (openViewDetailsModal) {
      // when view icon click set single state
      store.recurringInvoices.setSingleState(record.toJSON());
      store.recurringInvoices.setInvoiceNr(record.id);
    }
    store.recurringInvoiceUtilities.toggleViewDetailsModal();
  };

  const toggleViewProfileModal = (record) => {
    const openViewDetailsModal = record;
    if (openViewDetailsModal) {
      // when view icon click set single state
      store.recurringInvoices.setSingleState(record.toJSON());
    }
    store.recurringInvoiceUtilities.toggleViewProfileModal();
  };

  const TOTAL = () => {
    const Subscription = store?.recurringInvoices?.singleState?.Subscription;
    const TOTAL = computeTotal(Subscription);
    return <ExactTitle text={`${t('Total Fr.')} ${TOTAL}`} level={3} />;
  };
  const downloadInvoice = async () => {
    store.recurringInvoices.singleState.setIsExport(true); //disable export button
    const Subscriptions = [];
    let subtotal = 0;
    const contact_person = await getContactPerson(
      store.recurringInvoices.singleState.User.toJSON()
    );
    const _subscription = store.recurringInvoices.singleState.Subscription;
    const plan_type = plan[_subscription.payment_type].name;
    const plan_name = plan[_subscription.payment_type].textPDF;
    subtotal = subtotal + foundLizenzen(_subscription.license_id)[plan_type] * _subscription.number;
    Subscriptions.push({
      purchase_date: dayjs(_subscription.purchase_date).format('DD.MM.YYYY'),
      end_date: dayjs(_subscription.end_date).format('DD.MM.YYYY'),
      art_der_lizenz: foundLizenzen(_subscription.license_id).art_der_lizenz,
      nr: store.recurringInvoices.singleState.nr,
      number: _subscription.number,
      total: foundLizenzen(_subscription.license_id)[plan_type] * _subscription.number,
      plan_name,
      price: foundLizenzen(_subscription.license_id)[plan_type],
      per_price:
        _subscription.payment_type === 2 &&
        (foundLizenzen(_subscription.license_id)[plan_type] / 12).toFixed(1)
    });
    const [success, error] = await store.recurringInvoices.downloadInvoice(
      Subscriptions,
      subtotal,
      contact_person
    );
    store.recurringInvoices.singleState.setIsExport(false);

    return;
  };

  const handleSearch = async (search) => {
    search = search.trim();
    const props = store.recurringInvoiceUtilities.props;
    let params = { search, props };

    store.recurringInvoiceUtilities.setSearch(search);
    await store.recurringInvoices.LIST(params);
  };
  const onChangePage = async (page) => {
    store.recurringInvoiceUtilities.setPageNumber(page);
    const search = store.recurringInvoiceUtilities.search;
    const props = store.recurringInvoiceUtilities.props;

    let params = { page, search, props };

    await store.recurringInvoices.LIST(params);
  };

  return {
    onChangePage,
    downloadInvoice,
    getRecurringInvoice,
    handleChangeStatus,
    toggleViewDetailsModal,
    toggleViewProfileModal,
    TOTAL,
    getContactPerson,
    computeTotal,
    handleSearch
  };
};

export default RecurringInvoiceController;
