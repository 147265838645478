import React, { Suspense, useState } from 'react';
import { Form } from 'antd';

import { useTranslation } from 'react-i18next';

/**APP IMPORTS */
import { PaymentHistoryHeader, PaymentHistoryTable } from '@app_modules/PaymentHistory/components/';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

function PaymentHistory() {
  const [form] = Form.useForm();

  return (
    <>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <PaymentHistoryHeader form={form} />
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <PaymentHistoryTable form={form} />
      </Suspense>
    </>
  );
}

export default PaymentHistory;
