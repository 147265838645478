import {
  LizenzenState,
  SubscriptionState,
  subscriptionUtilities,
  AddSubscriptionState
} from '@app_modules/Lizenzen/model/';

import { LizenzenInfoState, lizenzenInfoUtilities } from '@app_modules/ManageLizenzen/model/';
import { InvoiceState, invoiceUtilities } from '@app_modules/PaymentHistory/model/';
import {
  RecurringInvoiceState,
  RecurringInvoiceUtilities
} from '@app_modules/RecurringInvoice/model/';

import { types } from 'mobx-state-tree';

const AppStore = {
  recurringInvoices: types.optional(RecurringInvoiceState, {}),
  recurringInvoiceUtilities: types.optional(RecurringInvoiceUtilities, {}),
  addSubscription: types.optional(AddSubscriptionState, {}),
  lizenzen: types.optional(LizenzenState, {}),
  lizenzenInfo: types.optional(LizenzenInfoState, {}),
  subscription: types.optional(SubscriptionState, {}),
  invoice: types.optional(InvoiceState, {}),
  lizenzenInfoUtilities: types.optional(lizenzenInfoUtilities, {}),
  invoiceUtilities: types.optional(invoiceUtilities, {}),
  subscriptionUtilities: types.optional(subscriptionUtilities, {})
};

export default AppStore;
