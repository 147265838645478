import { types } from 'mobx-state-tree';

const RecurringInvoiceModel = types
  .model('RecurringInvoiceModel', {
    id: types.optional(types.number, 0),
    key: types.optional(types.number, 0),
    license_id: types.optional(types.number, 0),
    invoice_date: types.optional(types.string, ''),
    frequency: types.optional(types.number, 0),
    timestamp: types.optional(types.string, ''),
    active: types.optional(types.boolean, true)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default RecurringInvoiceModel;
