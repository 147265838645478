import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Menu } from 'antd';
import { default as Icon, DashboardOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**APP IMPORTS */
import { AppMenusController } from '@app_modules/AppMenus/view/';

/**CORE IMPORTS */
import { Routes } from '@core_routing/';
import { UserSettingsSVG } from '@core_modules/Layout/view/CustomIcons';
import { LayoutController } from '@core_modules/Layout/controller';

const { SubMenu } = Menu;
function MainMenu() {
  const { AdminMenus } = AppMenusController();
  const { t } = useTranslation('common');
  const history = useHistory();
  const [selectedKeys, setSelectedKeys] = useState(['0']);

  const UserSettingsIcon = (props) => <Icon component={UserSettingsSVG} {...props} />;

  const { setDefaultMenu } = LayoutController({ history, setSelectedKeys });

  // eslint-disable-next-line
  useEffect(setDefaultMenu, [history.location.pathname]);
  useEffect(() => {
    console.log(history.location.pathname, 'oo');
    return () => {};
  }, [history.location.pathname]);
  return (
    <Menu
      className="h-100"
      theme="light"
      mode="inline"
      triggerSubMenuAction="hover"
      selectedKeys={selectedKeys}
    >
      <Menu.Item key="/" icon={<DashboardOutlined />}>
        <Link to={Routes.HOME_ROUTE}>{t('Dashboard')}</Link>
      </Menu.Item>
      <Menu.Item key="/user-management" icon={<UserSettingsIcon />}>
        <Link to={Routes.USER_MANAGEMENT_ROUTE}>{t('User Management')}</Link>
      </Menu.Item>
      {AdminMenus.map((menu) => {
        if (menu.isSubMenu) {
          return (
            <SubMenu key={menu.key} icon={menu.icon} title={menu.menuName}>
              {menu.subMenus.map((subMenu) => {
                return (
                  <Menu.Item key={subMenu.route}>
                    <Link to={subMenu.route}>{subMenu.menuName}</Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
          );
        }
        if (!menu.isSubMenu) {
          return (
            <Menu.Item key={menu.route} icon={menu.icon}>
              <Link to={menu.route}>{t(menu.menuName)}</Link>
            </Menu.Item>
          );
        }
        return '';
      })}
    </Menu>
  );
}

export default MainMenu;
