import { types } from 'mobx-state-tree';
import { UsersModel } from '@core_modules/UserManagement/model/';
import { SubscriptionModel } from '@app_modules/Lizenzen/model/';

const RecurringInvoiceModel = types
  .model('RecurringInvoiceModel', {
    id: types.optional(types.number, 0),
    key: types.optional(types.number, 0),
    nr: types.optional(types.string, ''),
    license_id: types.optional(types.number, 0),
    last_invoice: types.optional(types.maybeNull(types.string), ''),
    invoice_date: types.optional(types.string, ''),
    frequency: types.optional(types.number, 0),
    timestamp: types.optional(types.string, ''),
    active: types.optional(types.boolean, true),
    isExporting: types.optional(types.boolean, false),
    User: types.optional(UsersModel, {}),
    Subscription: types.optional(SubscriptionModel, {})
  })
  .views((self) => ({}))
  .actions((self) => ({
    setIsExport(TrueOrFalse) {
      self.isExporting = TrueOrFalse;
    }
  }));

export default RecurringInvoiceModel;
