import { types, flow, cast } from 'mobx-state-tree';
import InvoiceModel from './InvoiceModel';
import axios from 'axios';
//DAYJS
import dayjs from 'dayjs';

import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';

const API_PATH = '/api/invoice';
const GET_RECURRING_INVOICE = '/get-invoice';

export default types
  .model('InvoiceState', {
    state: types.optional(types.array(InvoiceModel), []),
    singleState: types.optional(InvoiceModel, {}),
    invoice_nr: types.optional(types.number, 99999),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),
    GET_RECURRING_INVOICE_BY_USER: flow(function* (params, shouldReturnList = false) {
      try {
        //APPLY PAGINATION
        self.loading = true;

        const { data } = yield axios.get(`${API_PATH}${GET_RECURRING_INVOICE}`, { params });

        const datWithKey = data.map((d) => ({ ...d, key: d.id }));

        self.state = cast(datWithKey);
        self.loading = false;

        // if (shouldReturnList) {
        return [datWithKey, null];
        // }
      } catch (error) {
        self.loading = false;
        return [null, error];
      }
    }),
    setInvoiceSingleState(record) {
      self.singleState = Object.assign(record);
    },
    setInvoiceNr(invoice_nr) {
      self.invoice_nr = invoice_nr;
    },
    downloadInvoice: flow(function* (Subscriptions, subtotal, contact_person, invoiceData) {
      const date_now = dayjs().format('LL');
      let invoice_date = '';
      let due_date = '';
      let payable_until = '';
      const mwst = subtotal * 0.077;
      const betrag_mwst = (mwst + subtotal).toFixed(2);

      //export from Payment History Table
      if (invoiceData) {
        invoice_date = dayjs(invoiceData.invoice_date).format('DD.MM.YYYY');
        due_date = dayjs(invoiceData.due_date).format('DD.MM.YYYY');
        payable_until = dayjs(invoiceData.due_date).add(30, 'day').format('DD.MM.YYYY');
      }

      //export from View Details
      if (!invoiceData) {
        invoice_date = dayjs(self.singleState.invoice_date).format('DD.MM.YYYY');
        due_date = dayjs(self.singleState.due_date).format('DD.MM.YYYY');
        payable_until = dayjs(self.singleState.invoice_date).add(30, 'day').format('DD.MM.YYYY');
      }
      const invoice_data = invoiceData !== null ? invoiceData : self.singleState;

      try {
        const options = [
          {
            ...invoice_data,
            date_now: date_now,
            Subscriptions,
            subtotal: subtotal.toFixed(2),
            invoice_date,
            due_date,
            contact_person,
            payable_until,
            betrag_mwst,
            mwst: mwst.toFixed(2)
          }
        ];
        const { data } = yield axios.post(`/api/download/invoice-customer/`, ...options);
        // Opens new tab to preview and download
        window.open(data, '_blank');
        return [data, null];
      } catch (error) {
        return [null, error];
      }
    })
  }))
  .views((self) => ({}));
