import { message, Button, Modal } from 'antd';

/**CORE IMPORTS */
import { GlobalController } from '@core_modules/Global/Controller';

//DAYJS
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
const LizenzenController = ({
  store,
  form,
  t,
  confirm,
  setisLicenseCodeValid,
  isLicenseCodeValid,
  setsubscriptionState,
  forceUpdate
}) => {
  dayjs.extend(localizedFormat);

  const { _showresultMessage } = GlobalController({ t });

  const getLicenseCodeError = () => {
    // const num = 500;
    // return num.forEach((number) => {
    //   return isLicenseCodeValid[number].validateStatus === 'error';
    // });
  };

  const computeTotal = () => {
    let total = 0;
    store.addSubscription.state.map((row) => {
      if (row.payment_type === 1) {
        total = total + row.number * row.monatspreis;
      }
      if (row.payment_type === 2) {
        total = total + row.number * row.jahrespreis;
      }
      return total;
    });
    return total;
  };

  const onChangeSelectPaymentType = ({ value, id }) => {
    const keyToBeUpdate = {
      name: 'payment_type',
      value
    };
    store.addSubscription.UpdateLocal({ keyToBeUpdate, id });
  };
  const onChangeNumber = ({ value, id }) => {
    if (value < 1) {
      value = 0;
    }
    const keyToBeUpdate = {
      name: 'number',
      value: value || 0
    };

    store.addSubscription.UpdateLocal({ keyToBeUpdate, id });
  };
  const getSubscriptionByUser = async () => {
    const user_id = store.login.id;

    let params = { user_id };
    const [data, error] = await store.subscription.GET_SUBSCRIPTION_BY_USER(params);
  };
  const getLizenzenInfos = async () => {
    const page = undefined;

    const search = store.lizenzenInfoUtilities.search;
    const props = store.lizenzenInfoUtilities.props;
    let params = { page, search, props };

    const [data] = await store.lizenzenInfo.LIST(params);
    const modifiedData = await data?.map((d) => ({
      ...d,
      user_id: store.login.id,
      license_id: d.id
    }));
    const [message, error] = store.addSubscription.InitializeAddSubscription(modifiedData);
  };

  const handleSearch = (search) => {
    store.subscriptionUtilities.setSearchKeyword(search);
  };

  const onChangePage = () => {};

  const toggleAddOrUpdateSubscriptionModal = ({ isUpdate = false, DATA = null }) => {
    if (isUpdate) {
      form.setFieldsValue(DATA);
      store.subscriptionUtilities.setUpdateId(DATA?.id.toString());
    }
    store.subscriptionUtilities.toggleAddOrUpdateSubscriptionModal(isUpdate);
  };

  const toggleUpdateSubscriptionModal = (state) => {
    store.subscriptionUtilities.setUpdateSubscriptionModal(state);
  };

  const showUpdateLicenseCodeModal = ({ record, license_id }) => {
    store.subscription.setLicenseCodes(record.toJSON());
    // store.subscriptionUtilities.setSubscription(record?.toJSON());
    store.subscription.setViewID(record.id);
    toggleUpdateSubscriptionModal(true);
    store.subscriptionUtilities.setNR(foundLizenzen(license_id).nr);
  };

  const handleSubmit = async () => {
    message.loading({
      content: t('Adding New Subscription'),
      key: 'addingNewSubscription'
    });
    const [successReferenceCode, error] = await store.subscription.CREATE_REFERENCE_CODE();

    if (error) {
      return message.error(t('Internal Server Error'));
    }

    await store.addSubscription.state.forEach(async (element) => {
      const anzalNotEmpty = element.number > 0;
      let end_date = '';

      if (anzalNotEmpty) {
        if (element.payment_type === 1) {
          end_date = dayjs().add(1, 'month').endOf('month');
        }
        if (element.payment_type === 2) {
          end_date = dayjs().add(13, 'month').endOf('month');
        }

        let subscription = {
          ...element,
          status: 1,
          end_date,
          reference_id: successReferenceCode.data.id
        };
        delete subscription.id;
        delete subscription.createdAt;
        delete subscription.updatedAt;

        const [success, error] = await store.subscription.CREATE(subscription);
        if (success) {
          const datetime_subscribed = dayjs(element.createdAt).format('LLL');
          const billing_date = dayjs(element.end_date).format('DD.MMM.YYYY');
          setsubscriptionState((prev) => [
            ...prev,
            {
              ...success.data,
              art_der_lizenz: element.art_der_lizenz,
              billing_date,
              datetime_subscribed
            }
          ]);
        }
        if (error) {
          message.error({
            content: t('Failed to subscribe'),
            key: element.id
          });
          return;
        }
        const { data } = success;

        let invoice_date = ''; // get the invoice date, which is the 10 day before last month
        if (element.payment_type === 1) {
          invoice_date = dayjs().add(1, 'month').endOf('month').subtract(10, 'day');
        }
        if (element.payment_type === 2) {
          //invoice_date = new Date(today.getFullYear(), today.getMonth() + 14, 0 - 10);
          invoice_date = dayjs().add(13, 'month').endOf('month').subtract(10, 'day');
        }

        const recurringInvoiceValues = {
          invoice_date,
          license_id: element.license_id,
          user_id: element.user_id,
          subscription_id: data.id,
          frequency: element.payment_type,
          number: element.number,
          art_der_lizenz: element.art_der_lizenz
        };
        store.addSubscription.createRecurringInvoice(recurringInvoiceValues, t, store);
        const createLicenseCodes = (count, data) => {
          if (count < 1) {
            return;
          }
          const values = {
            license_code: '',
            comment: '',
            subscription_id: data.id
          };
          store.subscription.CREATE_LICENSE_CODE(values);
          createLicenseCodes(count - 1, data);
        };
        await createLicenseCodes(element.number * element.anzahl, data);
      }
    });
    setTimeout(() => {
      store.subscription.setIsSendSubscribeMail(true);
    }, 5000);

    store.subscriptionUtilities.setisAddOrUpdateSubscriptionVisible(false);

    return false;
  };

  const foundLizenzen = (license_id) => {
    const found = store.lizenzenInfo.state.find((element) => element.id === license_id);
    return found;
  };

  const toggleUnsubscribe = async ({
    values,
    isUpdate,
    isSelf = false,
    updateId,
    unSubscribe = false
  }) => {
    message.loading({
      content: t('Updating Subscription'),
      key: 'updatingNewSubscription'
    });

    const params = isUpdate ? [updateId, values] : [values];

    let [successMessage, error] = await store.subscription['UPDATE'](...params);
    //updating Recurring invoice of the subscription to +30 days current date
    if (successMessage) {
      const today = new Date();

      let values = {
        ...successMessage.data.RecurringInvoices,
        invoice_date: today.setDate(today.getDate() + 20)
      };
      const recurringInvoicesParams = [successMessage.data.RecurringInvoices.id, values];

      let [successMessageRecurringInvoices, errorRecurringInvoices] = await store.recurringInvoices[
        'UPDATE'
      ](...recurringInvoicesParams);
      console.log(
        '🚀 ~ file: lizenzenController.js ~ line 194 ~ errorRecurringInvoices',
        errorRecurringInvoices
      );
    }

    console.log('🚀 ~ file: lizenzenController.js ~ line 182 ~ successMessage', successMessage);
    if (error) return;
    const { data } = successMessage;
    if (unSubscribe) {
      const today = new Date();
      today.setDate(today.getDate() + 30);
      const expired_date = dayjs(today).format('LL');

      const unsubscribeMessage = t(
        `You are successfully unsubscribe, After 30 days this plan won't be accessible anymore`
      );
      const newData = {
        ...data,
        email: store.login.email,
        cancellation_date: dayjs(data.cancellation_date).format('LL'),
        expired_date,
        unsubscribeMessage
      };
      let [unsubscribeSuccessMessage, unsubscribeError] = await store.subscription.unsubscribeMail(
        newData
      );
    }

    store.subscription.setUpdatedAt();

    let success = await _showresultMessage(error, {
      successMessage
    });

    if (success && !isSelf) {
      form.resetFields();
      store.lizenzenInfoUtilities.setAddLicenseModal(false);
    }
  };

  const showConfirm = ({ values, updateId }) => {
    const unSubscribe = true;
    confirm({
      title: (
        <>
          {t('Are you sure you want to unsubscribe?')}
          <br /> {t('This action cannot be undone.')}
        </>
      ),
      icon: '',
      onOk() {
        toggleUnsubscribe({ values, isUpdate: true, updateId, unSubscribe });
        showEmailSentMsg();
      },

      okText: t('Confirm'),
      centered: true
    });
  };

  const showEmailSentMsg = () => {
    Modal.info({
      title: (
        <>
          {t('An email has been sent to you.')}
          <br />
          {t('After 30-day period, this plan will no be assessible.')}
        </>
      ),
      icon: '',
      centered: true,
      okButtonProps: { size: 'large' }
    });
  };

  const handleSaveLicenseCodes = () => {
    setTimeout(async () => {
      const [successMessage, error] = await store.subscription.UPDATE_LICENSE_CODE();
      _showresultMessage(error, {
        successMessage
      });
    }, 1000);
  };

  function modulo(divident, divisor) {
    var cDivident = '';
    var cRest = '';

    for (var i in divident) {
      var cChar = divident[i];
      var cOperator = cRest + '' + cDivident + '' + cChar;

      if (cOperator < parseInt(divisor)) {
        cDivident += '' + cChar;
      } else {
        cRest = cOperator % divisor;
        if (cRest === 0) {
          cRest = '';
        }
        cDivident = '';
      }
    }
    cRest += '' + cDivident;
    if (cRest === '') {
      cRest = 0;
    }
    return cRest;
  }

  const onChangeLicenseCode = async ({ e, record }) => {
    record.updateLicenseCode(e.target.value);
  };
  const onBlurLicenseCode = async ({ e, record }) => {
    const input_value = e.target.value;
    if (!input_value) {
      setisLicenseCodeValid((prevState) => {
        const propertyKEY = record.id;
        return {
          ...prevState,
          [propertyKEY]: {
            validateStatus: 'error',
            help: t('License code is empty')
          }
        };
      });
      return;
    }
    // CHECK IF LICENSE CODE IS VALID
    record.setValidation('validating');
    setisLicenseCodeValid((prevState) => {
      const propertyKEY = record.id;
      return {
        ...prevState,
        [propertyKEY]: {
          validateStatus: 'validating',
          help: ''
        },
        all: { validateStatus: 'error', help: '' }
      };
    });
    //   store.subscription.setlicenseCodeUpdatedAt();

    record.updateLicenseCode(e.target.value);

    const serialNumber = parseInt(input_value.substr(4, 20)) + '271500';

    let checkt_digit = 98 - modulo(serialNumber, 97);

    const thirdAndFourthDigit = parseInt(input_value.substr(2, 2));

    const licenseCodeIsValid = checkt_digit === thirdAndFourthDigit;
    if (licenseCodeIsValid) {
      //check if license code  exist in tblKEY
      const [success] = await record.findLicense();
      console.log(
        '🚀 ~ file: lizenzenController.js ~ line 378 ~ onBlurLicenseCode ~ success',
        success
      );
      const licenseExist = success;
      if (licenseExist) {
        setisLicenseCodeValid((prevState) => {
          const propertyKEY = record.id;
          return {
            ...prevState,
            [propertyKEY]: {
              validateStatus: 'success',
              help: ''
            },
            all: { validateStatus: 'success', help: '' },
            initial: { validateStatus: 'success', help: '' }
          };
        });
      }
      const licenseNotExist = !success || success.length === 0;
      if (licenseNotExist) {
        setisLicenseCodeValid((prevState) => {
          const propertyKEY = record.id;
          return {
            ...prevState,
            [propertyKEY]: {
              validateStatus: 'error',
              help: t('License code not exist')
            },
            all: { validateStatus: 'error', help: t('License code not exist') }
          };
        });
      }
    }
    const licenseCodeIsNotValid = checkt_digit !== thirdAndFourthDigit;
    if (licenseCodeIsNotValid) {
      setisLicenseCodeValid((prevState) => {
        const propertyKEY = record.id;
        return {
          ...prevState,
          [propertyKEY]: {
            validateStatus: 'error',
            help: t('License code not valid')
          },
          all: { validateStatus: 'error', help: t('License code not exist') }
        };
      });
    }
  };
  const onBlurComment = ({ e, record }) => {
    record.updateComment(e.target.value);
    setisLicenseCodeValid((prevState) => {
      return {
        ...prevState,
        initial: { validateStatus: 'success', help: '' }
      };
    });
  };

  const totalNumber = () => {
    let total = 0;
    store.addSubscription.state.forEach((subscription) => {
      total = subscription.number + total;
    });
    return total;
  };

  return {
    computeTotal,
    totalNumber,
    onBlurComment,
    onChangeLicenseCode,
    toggleUnsubscribe,
    getLizenzenInfos,
    onChangePage,
    handleSearch,
    toggleAddOrUpdateSubscriptionModal,
    toggleUpdateSubscriptionModal,
    showUpdateLicenseCodeModal,
    onChangeSelectPaymentType,
    onChangeNumber,
    handleSubmit,
    getSubscriptionByUser,
    foundLizenzen,
    showConfirm,
    handleSaveLicenseCodes,
    getLicenseCodeError,
    onBlurLicenseCode
  };
};
export default LizenzenController;
