import React, { useEffect, Suspense } from 'react';

import { Button, Divider, Space, Switch, Table, Typography } from 'antd';
import { default as Icon, EyeFilled, ExportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import dayjs from 'dayjs';
/**APP IMPORTS */
import { PaymentHistoryController } from '@app_modules/PaymentHistory/controller';
import { LizenzenController } from '@app_modules/Lizenzen/controller';

import { ViewDetailsModal } from '@app_modules/PaymentHistory/components';
import { ExportFilled } from '@app_assets/svg/CustomIcon';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

import './styles.css';
import { compare } from '@app_data/utils';

const { Text } = Typography;

function PaymentHistoryTable({ store, form }) {
  const { t } = useTranslation('common');

  const {
    getRecurringInvoiceByUser,
    getRecurringInvoices,
    onChangePage,
    toggleViewDetailsModal,
    computeTotal,
    downloadInvoice
  } = PaymentHistoryController({
    store,
    form,
    t
  });
  const { getLizenzenInfos } = LizenzenController({
    store,
    form,
    t
  });

  const inititalize = {
    1: getRecurringInvoices,
    2: getRecurringInvoiceByUser
  };

  const ExportFilledIcon = (props) => <Icon component={ExportFilled} {...props} />;

  useEffect(() => {
    getLizenzenInfos();
    const user_type_id = store.login.user_type_id;
    inititalize[user_type_id]();
    return () => {};
    // eslint-disable-next-line
  }, []);
  const columns = [
    {
      title: t('Profile Name').toUpperCase(),
      dataIndex: 'User',
      sorter: (a, b) => a.User.first_name.localeCompare(b.User.first_name),
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (User, record) => {
        const type = User.employment;

        const Employment = {
          1: `${User.company_name}`,
          2: `${User.first_name} ${User.last_name}`
        };
        return (
          <>
            <Space>{Employment[type]} </Space>
          </>
        );
      }
    },
    {
      title: t('Invoice NR').toUpperCase(),
      dataIndex: 'id',
      align: 'center',
      render: (text) => <>{text}</>,
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      sorter: (a, b) => a.invoice_nr - b.invoice_nr
    },
    {
      title: t('Invoice DATE').toUpperCase(),
      dataIndex: 'invoice_date',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      sorter: (a, b) => a.invoice_date.localeCompare(b.invoice_date),
      render: (date) => {
        return <>{dayjs(date).format('DD.MMMM.YYYY')}</>;
      }
    },
    {
      title: t('DUE DATE').toUpperCase(),
      dataIndex: 'due_date',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      sorter: (a, b) => a.invoice_date.localeCompare(b.invoice_date),
      render: (date) => {
        return <>{dayjs(date).format('DD.MMMM.YYYY')}</>;
      }
    },
    {
      title: t('TOTAL PREIS').toUpperCase(),
      align: 'center',
      dataIndex: 'Subscriptions',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (Subscriptions) => <>{computeTotal(Subscriptions)}</>
    },

    {
      title: t('STATUS').toUpperCase(),
      dataIndex: 'Status',
      responsive: ['xl'],
      width: 200,
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (Status) => {
        return (
          <div style={{ width: `100%` }}>
            <div
              className={`exact-status-box ${Status.status}`}
              style={{ width: `80%`, marginLeft: '1vw' }}
            >
              {Status?.status}
            </div>
          </div>
        );
      }
    },

    {
      title: t('Action').toUpperCase(),
      dataIndex: 'action',
      responsive: ['lg', 'xl'],
      align: 'center',

      render: (value, invoiceData) => {
        console.log(
          '🚀 ~ file: PaymentHistoryTable.js ~ line 126 ~ PaymentHistoryTable ~ invoiceData',
          invoiceData.isExporting
        );
        return (
          <>
            <Space>
              <Button
                icon={<EyeFilled style={{ color: '#1D508D' }} />}
                onClick={() => toggleViewDetailsModal(invoiceData)}
                type="link"
              />
              <Button
                onClick={() => downloadInvoice(invoiceData)}
                icon={<ExportFilledIcon />}
                type="link"
                loading={invoiceData.isExporting}
              />
            </Space>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Table
        loading={store?.invoice?.loading}
        className="exact-table exact-center shadow-sm bg-white p-3"
        size="small"
        columns={columns}
        dataSource={store?.invoice?.state?.toJSON().sort(compare)}
        pagination={{
          position: ['bottomCenter'],
          hideOnSinglePage: true,
          pageSize: 10,
          responsive: true,
          onChange: onChangePage,
          showTotal: (total, range) => {
            return (
              <div>
                {range[0]}-{range[1]} {t('of')} {total} {t('Invoice')}{' '}
              </div>
            );
          },
          // pageSizeOptions: [5, 10, 15, 20],
          showSizeChanger: false,
          total: store.invoice.toJSON().total
        }}
      />

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ViewDetailsModal form={form} />
      </Suspense>
    </>
  );
}

export default inject('store')(observer(PaymentHistoryTable));
