import { message } from 'antd';

/**CORE IMPORTS */
import { GlobalController } from '@core_modules/Global/Controller';

const PaymentHistoryController = ({ store, form, t }) => {
  const { _showresultMessage } = GlobalController({ t });
  const getLizenzenInfos = async () => {
    const page = 1;

    const search = store.lizenzenInfoUtilities.search;
    const props = store.lizenzenInfoUtilities.props;
    let params = { page, search, props };

    await store.lizenzenInfo.LIST(params);
  };

  const handleSearch = async (search) => {
    search = search.trim();
    const props = store.lizenzenInfoUtilities.props;
    let params = { search, props };

    store.lizenzenInfoUtilities.setSearch(search);
    await store.lizenzenInfo.LIST(params);
  };

  const onChangePage = async (page) => {
    const search = store.lizenzenInfoUtilities.search;
    const props = store.lizenzenInfoUtilities.props;

    let params = { page, search, props };

    await store.lizenzenInfo.LIST(params);
  };

  const toggleViewDetailsModal = () => {
    store.paymentHistoryUtilities.toggleViewDetailsModal();
  };

  const toggleAddOrUpdateLicenseModal = ({ isUpdate = false, DATA = null }) => {
    if (isUpdate) {
      form.setFieldsValue(DATA);
      store.lizenzenInfoUtilities.setUpdateId(DATA?.id);
    }
    if (!isUpdate) form.resetFields();
    store.lizenzenInfoUtilities.toggleShowAddOrUpdateLicenseModal(isUpdate);
  };

  const handleAddOrUpdateLicense = async ({ values, isUpdate, isSelf = false }) => {
    message.loading({
      content: isUpdate ? t('Updating License') : t('Adding New License'),
      key: 'addingNewLicense'
    });

    const params = isUpdate
      ? [isSelf ? store.lizenzenInfo.id : store.lizenzenInfoUtilities.updateId, values]
      : [values];

    let [successMessage, error] = await store.lizenzenInfo[isUpdate ? 'UPDATE' : 'CREATE'](
      ...params
    );

    //let [successMessage, error] = await store.lizenzenInfo.CREATE(values);

    let success = await _showresultMessage(error, {
      successMessage
    });

    if (success && !isSelf) {
      form.resetFields();
      store.lizenzenInfoUtilities.setAddLicenseModal(false);
    }
  };
  const handleChangeStatus = async ({ aktiv, invoiceData }) => {
    let [successMessage, error] = await store.lizenzenInfo.UPDATE(invoiceData.id, { aktiv });

    await _showresultMessage(error, {
      successMessage
    });
  };

  return {
    getLizenzenInfos,
    onChangePage,
    handleSearch,
    toggleViewDetailsModal,
    toggleAddOrUpdateLicenseModal,
    handleAddOrUpdateLicense,
    handleChangeStatus
  };
};
export default PaymentHistoryController;
