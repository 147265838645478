import React from 'react';
import { Col, Row, Typography, Input } from 'antd';
/**CORE IMPORTS */
import { TWO_GRID } from '@core_common/antdhelpers/constants';
// TRANSLATION
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';

const { Text } = Typography;
function CompanyUserDetails({ store }) {
  const { t } = useTranslation('common');
  const { User } = store.recurringInvoices.singleState || {};
  return (
    <div>
      <Row gutter={[16, 0]}>
        <Col {...TWO_GRID}>
          <Text> {t('Company Name')} </Text>
          <Input style={{ height: '3vh' }} value={User.company_name} className="w-100 mt-top-1vh" />
        </Col>
        <Col {...TWO_GRID}>
          <Text>{t('Contact Person')}</Text>
          <Input value={User.contact_person} className="w-100 mt-top-1vh" />
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 0]}>
        <Col {...TWO_GRID}>
          <div>{t('Address')}</div>

          <Input value={User.additional_address} className="w-100 mt-top-1vh" />
        </Col>
        <Col {...TWO_GRID}>
          <div>{t('Postal Code')}</div>

          <Input value={User.zip_code} className="w-100 mt-top-1vh" />
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 0]}>
        <Col {...TWO_GRID}>
          <div>{t('Email Address')}</div>

          <Input value={User.email} className="w-100 mt-top-1vh" />
        </Col>
        <Col {...TWO_GRID}>
          <div>{t('Phone Number')}</div>

          <Input value={User.phone_number} className="w-100 mt-top-1vh" />
        </Col>
      </Row>
      <br />
    </div>
  );
}

export default inject('store')(observer(CompanyUserDetails));
