import { types, flow } from 'mobx-state-tree';
import { StatusModel, SubscriptionModel } from '@app_modules/Lizenzen/model';
import axios from 'axios';

//DAYJS
import dayjs from 'dayjs';
/* CORE IMPORTS */
import { UsersModel } from '@core_modules/UserManagement/model';

const API_PATH = '/api/recurring-invoice';

const InvoiceModel = types
  .model('InvoiceModel', {
    id: types.optional(types.number, 0),
    key: types.optional(types.number, 0),
    Status: types.optional(StatusModel, {}),
    Subscriptions: types.optional(types.array(SubscriptionModel), []),
    subscription_id: types.optional(types.number, 0),
    invoice_nr: types.optional(types.string, ''),
    invoice_date: types.optional(types.string, ''),
    due_date: types.optional(types.string, ''),
    date_paid: types.optional(types.maybeNull(types.string), ''),
    timestamp: types.optional(types.string, ''),
    status_id: types.optional(types.number, 0),
    active: types.optional(types.boolean, true),
    User: types.optional(UsersModel, {}),
    isExporting: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setIsExport(TrueOrFalse) {
      self.isExporting = TrueOrFalse;
    },
    SEND_INVOICE: flow(function* ({ userData, Subscriptions, subtotal, contact_person }) {
      const date_now = dayjs().format('LL');
      const invoice_date = dayjs(self.invoice_date).format('DD.MM.YYYY');
      const payable_until = dayjs(self.invoice_date).add(30, 'day').format('DD.MM.YYYY');
      const mwst = subtotal * 0.077;
      const betrag_mwst = (mwst + subtotal).toFixed(2);
      const invoice_dateTEXT = dayjs(self.invoice_date).format('LL');

      try {
        let { data } = yield axios.post(`${API_PATH}/sendmail-invoice`, {
          ...userData,
          invoice_dateTEXT,
          date_now,
          hasPDF: true, // for mailer to attached PDF
          invoice_date,
          payable_until,
          mwst,
          betrag_mwst,
          Subscriptions,
          subtotal,
          contact_person
        });
        return [data, null];
      } catch (error) {
        return [null, error];
      }
    })
  }));

export default InvoiceModel;
