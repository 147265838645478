import React, { useEffect } from 'react';
import { Form, Modal, Space, Button, Table, Typography, Input, Select, InputNumber } from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import AnimatedNumber from 'animated-number-react';
//Ant D
/** APP Imports */
import { LizenzenController } from '@app_modules/Lizenzen/controller';
/**CORE IMPORTS */
import { UseForceUpdate } from '@core_modules/Global/Component';
import { ExactText } from '@core_common/components';
import { v4 as uuidv4 } from 'uuid';
import './styles.css';
const { Title, Text } = Typography;
const { Option } = Select;

function AddSubscriptionModal({ store, form, setsubscriptionState }) {
  const { t } = useTranslation('common');
  const forceUpdate = UseForceUpdate();
  const formatValue = (value) => value.toFixed(2);
  const {
    onChangeNumber,
    toggleAddOrUpdateSubscriptionModal,
    onChangeSelectPaymentType,
    handleSubmit,
    totalNumber,
    computeTotal
  } = LizenzenController({
    store,
    form,
    t,
    setsubscriptionState,
    forceUpdate
  });

  const { isAddOrUpdateSubscriptionVisible } = store.subscriptionUtilities;
  const { isCreating } = store.subscription;

  const planArray = (price) => {
    return (
      <>
        <Option value={1} key={uuidv4()}>
          <div className="d-flex flex-column align-items-center justify-content-center mt-1">
            <Title style={{ marginBottom: 0 }} level={5}>
              {t('Monthly').toUpperCase()}
            </Title>
            <Text>
              {t('Fr')}. {price.monatspreis}
            </Text>
          </div>
        </Option>
        <Option value={2} key={uuidv4()}>
          <div className="d-flex flex-column align-items-center justify-content-center mt-1">
            <Title style={{ marginBottom: -4 }} level={5}>
              {t('Yearly').toUpperCase()}
            </Title>
            <Text style={{ marginBottom: -5 }}>
              {t('FR')}. {price.jahrespreis}
            </Text>
            <Text style={{ fontSize: 10 }}>
              Fr. ({(price.jahrespreis / 12).toFixed(2)} / {t('mo')})
            </Text>
          </div>
        </Option>
      </>
    );
  };

  const columns = [
    {
      title: t('NR').toUpperCase(),
      dataIndex: 'nr',
      sorter: (a, b) => a.nr.localeCompare(b.nr),
      align: 'center',
      render: (text) => <>{text}</>,
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl']
    },
    {
      title: t('ART DER LIZENZ').toUpperCase(),
      dataIndex: 'art_der_lizenz',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (art_der_lizenz) => <>{art_der_lizenz}</>
    },
    {
      title: t('Anzahl').toUpperCase(),
      dataIndex: 'id',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      width: 100,
      render: (id, record) => (
        <InputNumber
          placeholder={0}
          value={record.number}
          onChange={(value) => {
            onChangeNumber({ value, id });
          }}
        />
      )
    },

    {
      title: t('PLAN').toUpperCase(),
      dataIndex: 'id',
      align: 'center',
      responsive: ['xl'],
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (id, record) => {
        const price = {
          monatspreis: record.monatspreis,
          jahrespreis: record.jahrespreis
        };

        return (
          <>
            {/* <Row className="w-100" style={{ marginLeft: '1vw' }}>
              <Col className="w-100" col={24}> */}
            <div className="d-flex align-items-center justify-content-center">
              <Input.Group className="w-80 custom-dropdown" compact>
                <Select
                  defaultValue={1}
                  className="w-80 custom-dropdown-select"
                  onChange={(value) => onChangeSelectPaymentType({ value, id })}
                >
                  {planArray(price)}
                </Select>
              </Input.Group>
            </div>
            {/* </Col>
            
            </Row> */}
          </>
        );
      }
    },
    {
      title: t('TOTAL PREIS').toUpperCase(),
      dataIndex: 'id',
      responsive: ['xl'],
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (row, record) => {
        let total = 0;
        if (record.payment_type === 1) {
          total = record.number * record.monatspreis;
        }
        if (record.payment_type === 2) {
          total = record.number * record.jahrespreis;
        }

        return <Text>{total.toFixed(2)}</Text>;
      }
    }
  ];
  //eslint-disable-next-line
  // useEffect(getLizenzenInfos, []);
  useEffect(() => {
    console.log(store?.addSubscription?.state?.toJSON());
    return () => {};
  });
  console.log(totalNumber(), 'Total');
  return (
    <Modal
      title={<ExactText text={t('Add Subscription')} />}
      visible={isAddOrUpdateSubscriptionVisible}
      footer={null}
      className="exact-modal"
      style={{ top: 20 }}
      onCancel={toggleAddOrUpdateSubscriptionModal}
    >
      <Form
        form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={(values) => handleSubmit()}
      >
        <Table
          loading={store?.addSubscription?.loading}
          className="shadow-sm bg-white p-3"
          size="small"
          columns={columns}
          dataSource={store?.addSubscription?.state?.filter((state) => state.aktiv === true)}
          pagination={false}
        />
        <div style={{ textAlign: 'right', margin: '1vh 5vh 5vh 0vh' }}>
          <Title level={3}>
            {t('Total Fr.')} &nbsp;
            <AnimatedNumber value={computeTotal()} formatValue={formatValue} />
          </Title>
        </div>
        <Form.Item>
          <div className="d-flex w-100 justify-content-end">
            <Space>
              <Button onClick={toggleAddOrUpdateSubscriptionModal} type="default">
                {t('Cancel')}
              </Button>
              <Button
                disabled={totalNumber() === 0 ? true : false}
                loading={isCreating}
                type="primary"
                htmlType="submit"
              >
                {t('Subscribe')}
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default inject('store')(observer(AddSubscriptionModal));
