import { cast, types, flow } from 'mobx-state-tree';
import axios from 'axios';

import UserTypesModel from '@core_modules/UserManagement/model/UserTypesModel';
import CountryData from '@core_data/countries/Countries.json';

import SubscriptionModel from './SubscriptionModel';
const API_PATH = '/api/users';

const lizenzenInfoUtilities = types
  .model('lizenzenInfoUtilities', {
    isAddSubscriptionVisible: types.optional(types.boolean, false),
    isUpdateSubscriptionVisible: types.optional(types.boolean, false),
    subscription: types.optional(SubscriptionModel, {}),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), ['nr', 'art_der_lizenz', 'anzahl', 'aktiv']),
    userTypes: types.optional(types.array(UserTypesModel), []),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.string, ''),
    cities: types.optional(types.array(types.string), [])
  })
  .views((self) => ({}))
  .actions((self) => ({
    toggleAddOrUpdateSubscriptionModal() {
      self.isAddOrUpdateSubscriptionVisible = !self.isAddOrUpdateSubscriptionVisible;
    },
    setSubscription(record) {
      self.subscription = Object.assign(record);
    },

    setUpdateId(id) {
      self.updateId = id;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },

    FETCH_USER_TYPES: flow(function* () {
      const { data } = yield axios.get(`${API_PATH}/types`);
      self.userTypes = cast(data);
    })
  }));

export default lizenzenInfoUtilities;
