import { types, flow, cast, isAlive } from 'mobx-state-tree';
import SubscriptionModel from './SubscriptionModel';
import LicenseCodesModel from './LicenseCodesModel';

import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
import axios from 'axios';
const API_PATH = '/api/subscription';
const UNSUBSCRIBE_MAIL = 'unsubscribe-sendmail';
const SUBSCRIBE_MAIL = 'subscribe-sendmail';

const GET_SUBSCRIPTION = '/api/getsubscription/byuser';
const API_PATH_LICENSE_CODE = '/api/license-code';
const API_PATH_LICENSE_CODE_INITIALIZE_CODES = '/api/license-code-normal/create';

export default types
  .model('SubscriptionState', {
    state: types.optional(types.array(SubscriptionModel), []),
    subscriptionState: types.optional(types.array(SubscriptionModel), []),
    viewID: types.optional(types.number, 0),
    singleState: types.optional(SubscriptionModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false),
    isSendSubscribeMail: types.optional(types.boolean, false),
    updatedAt: types.optional(types.number, new Date().getTime()),
    licenseCodeUpdatedAt: types.optional(types.number, new Date().getTime()),
    licenseCodes: types.optional(types.array(LicenseCodesModel), [])
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),
    setLoading(state) {
      self.loading = state;
    },
    setUpdatedAt() {
      self.updatedAt = new Date().getTime();
    },
    setlicenseCodeUpdatedAt() {
      self.licenseCodeUpdatedAt = new Date().getTime();
    },

    setViewID(id) {
      self.viewID = id;
    },
    insertSubscription(subscription) {
      try {
        self.subscriptionState.push({ id: subscription.id });
        return ['success', null];
      } catch (error) {
        console.log(
          '🚀🚀🚀 ~ file: SubscriptionState.js ~ line 54 ~ insertSubscription ~ error',
          error
        );
        return [null, error];
      }
    },
    unsubscribeMail: flow(function* (values) {
      try {
        const { data } = yield axios.post(`${API_PATH}/${UNSUBSCRIBE_MAIL}`, values);
        return [{ message: 'Updated!', data: data }, null];
      } catch (error) {
        return [null, error];
      }
    }),
    CREATE_REFERENCE_CODE: flow(function* () {
      try {
        const { data } = yield axios.post(`/api/reference-code`);
        return [{ message: 'Created!', data: data }, null];
      } catch (error) {
        return [null, error];
      }
    }),
    setIsSendSubscribeMail(TrueOrFalse) {
      self.isSendSubscribeMail = TrueOrFalse;
    },
    SEND_MAIL_SUBSCRIBE: flow(function* ({
      subscriptionStates,
      store,
      t,
      setsubscriptionState,
      capitalizeFirstLetter,
      getContactPerson
    }) {
      let rowData = ``;

      subscriptionStates.forEach((subscription) => {
        let paymentType = '';
        if (subscription.payment_type === 1) paymentType = t('Monthly');
        if (subscription.payment_type === 2) paymentType = t('Yearly');
        rowData =
          rowData +
          `<tr style="padding: 15px;font-weight:bold">
        <td style="padding: 15px;">${subscription.art_der_lizenz}</td>
        <td style="padding: 15px;">${subscription.number}</td>
        <td style="padding: 15px;">${paymentType}</td>
      </tr>`;
      });

      const User = store?.login?.toJSON();
      console.log('🚀🚀🚀 ~ file: SubscriptionState.js ~ line 99 ~ .actions ~ User', User);
      const full_name = capitalizeFirstLetter(getContactPerson(User));

      try {
        const isSubscriptionStateNotEmpty = subscriptionStates.length > 0;

        const payload = {
          rowData,
          subscriptionStates,
          email: store.login.email,
          full_name
        };

        // Check if the user add subscriptions

        if (isSubscriptionStateNotEmpty) {
          const { data } = yield axios.post(`${API_PATH}/${SUBSCRIBE_MAIL}`, payload);
          setsubscriptionState([]);
          self.isSendSubscribeMail = false;
          return [{ message: 'Send!', data: data }, null];
        }
        if (!isSubscriptionStateNotEmpty) {
          return [{ message: 'Empty' }, null];
        }
      } catch (error) {
        return [null, error];
      }
    }),
    UPDATE_LICENSE_CODE() {
      try {
        self.loading = true;
        self.licenseCodes.map(async (license) => {
          const { data } = await axios.put(`${API_PATH_LICENSE_CODE}/${license.id}`, license);
        });
        self.updatedAt = new Date().getTime();
        self.loading = false;
        return [{ message: 'Updated!' }, null];
      } catch (error) {
        return [null, error];
      }
    },
    setLicenseCodes(record) {
      self.licenseCodes = [];
      const data = record?.LicenseCodes;
      const datWithKey = data.map((d, index) => ({ ...d, key: d.id, number: index + 1 }));
      self.licenseCodes = cast(datWithKey);
    },
    resetLicenseCodes() {
      self.licenseCodes = [];
    },
    CREATE_LICENSE_CODE: flow(function* (values, count) {
      try {
        const { data } = yield axios.post(`${API_PATH_LICENSE_CODE}`, values);
        self.state = [];
        self.updatedAt = new Date().getTime();
        return [data, null];
        // }
      } catch (error) {
        return [null, error];
      }
    }),

    GET_SUBSCRIPTION_BY_USER: flow(function* (params, shouldReturnList = false) {
      try {
        //APPLY PAGINATION
        self.loading = true;

        const { data } = yield axios.get(`${GET_SUBSCRIPTION}`, { params });

        const datWithKey = data.map((d) => ({ ...d, key: d.id }));
        self.state = [];
        self.state = cast(datWithKey);
        self.loading = false;

        // if (shouldReturnList) {
        return [datWithKey, null];
        // }
      } catch (error) {
        return [null, error];
      }
    })
  }))
  .views((self) => ({}));
