const LIZENZEN_ROUTE = '/lizenzen';
const LIZENZEN_CLIENT_ROUTE = '/cl/lizenzen';
const PAYMENT_HISTORY_CLIENT_ROUTE = '/cl/payment-history';
const CREATE_ACCOUNT_ROUTE = '/gt/create-account';
const DROPDOWN_SETTING = '/dropdown-setting';
const MANAGE_LIZENZEN = '/manage-lizenzen';
const RECURRING_INVOICE = '/ad/recurring-invoice';
const PAYMENT_HISTORY_ADMIN_ROUTE = '/ad/payment-history';

const Routes = {
  LIZENZEN_ROUTE,
  LIZENZEN_CLIENT_ROUTE,
  PAYMENT_HISTORY_CLIENT_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  DROPDOWN_SETTING,
  MANAGE_LIZENZEN,
  RECURRING_INVOICE,
  PAYMENT_HISTORY_ADMIN_ROUTE
};
export default Routes;
