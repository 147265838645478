import { types } from 'mobx-state-tree';

const CustomerInfoModel = types
  .model('CustomerInfoModel', {
    id: types.optional(types.number, 0),
    user_id: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default CustomerInfoModel;
