import React, { useEffect, useState } from 'react';
import { Form, Modal, Space, Button, Table, Typography, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

//Ant D
/** APP Imports */
import { LizenzenController } from '@app_modules/Lizenzen/controller';
/**CORE IMPORTS */
import { ExactText } from '@core_common/components';
import { v4 as uuidv4 } from 'uuid';

const { Text } = Typography;

function UpdateLicenseCode({ store, form }) {
  const { t } = useTranslation('common');
  const [state, setstate] = useState(0);

  const initialisLicenseCodeValid = {
    all: { validateStatus: '', help: ' ' },
    initial: { validateStatus: 'error', help: ' ' }
  };

  const [isLicenseCodeValid, setisLicenseCodeValid] = useState(initialisLicenseCodeValid);

  const {
    toggleUpdateSubscriptionModal,
    handleSaveLicenseCodes,
    onBlurComment,
    onChangeLicenseCode,
    onBlurLicenseCode
  } = LizenzenController({
    store,
    form,
    t,
    setisLicenseCodeValid,
    isLicenseCodeValid
  });

  const { isUpdateSubscriptionVisible } = store.subscriptionUtilities;

  const { isCreating } = store.lizenzenInfo;

  useEffect(() => {
    setstate(1);
    console.log('state', state);
    store?.subscription?.licenseCodes.map((licenseCodes) => {
      return form.setFieldsValue({
        [licenseCodes.id]: licenseCodes.license_code
      });
    });
    return () => {};
  }, [isUpdateSubscriptionVisible]); // eslint-disable-line

  const columns = [
    {
      title: t('NR').toUpperCase(),
      dataIndex: 'number',
      render: (number) => <>{number}</>,
      shouldCellUpdate: (prev, next) =>
        JSON.stringify(prev) !== JSON.stringify(next) ||
        JSON.stringify(prev.checking) !== JSON.stringify(next.checking),
      responsive: ['sm', 'md', 'lg', 'xl'],
      sorter: (a, b) => a.number - b.number
    },
    {
      title: t('License Code').toUpperCase(),
      dataIndex: 'license_code',
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (license_code, record) => {
        const id = record.id;
        initialisLicenseCodeValid[id] = { validateStatus: '', help: '' };
        return (
          <Form.Item
            name={id}
            hasFeedback
            validateStatus={isLicenseCodeValid[id]?.validateStatus}
            help={isLicenseCodeValid[id]?.help}
            style={{ marginTop: '1.5vh' }}
          >
            <Input
              key={uuidv4()}
              onChange={(e) => onChangeLicenseCode({ e, record })}
              onBlur={(e) => onBlurLicenseCode({ e, record })}
            />
          </Form.Item>
        );
      }
    },
    {
      title: t('Comment').toUpperCase(),
      dataIndex: 'comment',
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (text, record) => (
        <Input onChange={(e) => onBlurComment({ e, record })} placeholder={text} value={text} />
      )
    }
  ];

  console.log(
    '🚀 ~ file: UpdateLicenseCode.js',
    !!form.getFieldsError().filter(({ errors }) => errors.length).length,
    ':',
    isLicenseCodeValid.all.validateStatus,
    ':',
    isLicenseCodeValid.initial.validateStatus,
    '%%%',
    Object.values(isLicenseCodeValid),
    Object.values(isLicenseCodeValid).filter(({ validateStatus }) => validateStatus === 'error')
  );

  return (
    <Modal
      title={<ExactText text={store.subscriptionUtilities.nr} />}
      visible={isUpdateSubscriptionVisible}
      footer={null}
      className="exact-modal"
      style={{ top: 20 }}
      onCancel={() => toggleUpdateSubscriptionModal(false)}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Form
        form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={(values) => handleSaveLicenseCodes(values)}
        onFieldsChange={(object) => console.log('***', object)}
      >
        <Table
          loading={store?.subscription?.loading}
          className="shadow-sm bg-white p-3"
          size="small"
          columns={columns}
          dataSource={store?.subscription?.licenseCodes.toJSON()}
          pagination={{ pageSize: 5, responsive: true }}
        />

        <Text>
          <div style={{ margin: '1vh 5vh 5vh 0vh', color: 'green' }}>
            {t('Note Please click outside the input to validate the license code')}
          </div>
        </Text>

        <div style={{ textAlign: 'right', margin: '1vh 5vh 5vh 0vh' }}></div>
        <Form.Item>
          <div className="d-flex w-100 justify-content-end">
            <Space>
              <Button
                disabled={store.subscription.loading}
                onClick={() => toggleUpdateSubscriptionModal(false)}
                type="default"
              >
                {t('Cancel')}
              </Button>
              <Button
                disabled={
                  store.subscription.loading ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                  isLicenseCodeValid.all.validateStatus === 'error' ||
                  isLicenseCodeValid.initial.validateStatus === 'error' ||
                  !!Object.values(isLicenseCodeValid).filter(
                    ({ validateStatus }) => validateStatus === 'error'
                  ).length
                }
                loading={isCreating}
                type="primary"
                htmlType="submit"
              >
                {t('Save')}
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default inject('store')(observer(UpdateLicenseCode));
