import React from 'react';
import { Row, Modal, Col, Table, Typography, Button } from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

//DAYJS
import dayjs from 'dayjs';
//Ant D
import { default as Icon } from '@ant-design/icons';
/** APP Imports */
import { ExportFilled } from '@app_assets/svg/CustomIcon';
import { PaymentHistoryController } from '@app_modules/PaymentHistory/controller';
import { RecurringInvoiceController } from '@app_modules/RecurringInvoice/controller';
/**CORE IMPORTS */
import { ExactText } from '@core_common/components';

const { Text } = Typography;

function ViewDetailsModal({ store, form }) {
  const { t } = useTranslation('common');

  const plan = {
    1: {
      text: t('Monthly'),
      textPDF: t('Monatslizenzen'),
      name: 'monatspreis',
      price: 1
    },
    2: {
      text: t('Yearly'),
      textPDF: t('Jahreslizenzen'),
      name: 'jahrespreis',
      price: 2
    }
  };

  const ExportFilledIcon = (props) => <Icon component={ExportFilled} {...props} />;
  const { foundLizenzen, computeTotal } = PaymentHistoryController({
    store,
    form,
    t
  });

  const { toggleViewDetailsModal, TOTAL, downloadInvoice, getContactPerson } =
    RecurringInvoiceController({
      store,
      t,
      computeTotal,
      plan
    });

  const { User, last_invoice, invoice_date, frequency, active } =
    store?.recurringInvoices?.singleState || {};
  const { isViewDetailsVisible } = store?.recurringInvoiceUtilities;

  const frequencyText = {
    1: t('1 month'),
    2: t('12 months')
  };

  const columns = [
    {
      title: t('Invoice Nr').toUpperCase(),
      dataIndex: 'nr',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (text) => <>{store?.recurringInvoices?.invoice_nr}</>
    },
    {
      title: t('ART DER LIZENZ').toUpperCase(),
      dataIndex: 'license_id',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (license_id) => <>{foundLizenzen(license_id).art_der_lizenz}</>
    },
    {
      title: t('ANZAHL').toUpperCase(),
      dataIndex: 'number',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl']
    },

    {
      title: t('PLAN').toUpperCase(),
      dataIndex: 'payment_type',
      responsive: ['xl'],
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (payment_type, record) => {
        const plan_name = plan[payment_type].text;
        const plan_type = plan[payment_type].name;

        return (
          <>
            <strong> {plan_name.toUpperCase()}</strong>
            <br />
            Fr {foundLizenzen(record.license_id)[plan_type]} <br />
            {payment_type === 2 ? (
              <span style={{ fontSize: '9px' }}>
                Fr {(foundLizenzen(record.license_id)[plan_type] / 12).toFixed(1)}/{t('mo')}{' '}
              </span>
            ) : (
              ''
            )}{' '}
          </>
        );
      }
    },
    {
      title: t('TOTAL PREIS').toUpperCase(),
      dataIndex: 'payment_type',
      responsive: ['xl'],
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (payment_type, record) => {
        const plan_name = plan[payment_type].text;
        const plan_type = plan[payment_type].name;
        return <Text> {foundLizenzen(record.license_id)[plan_type] * record.number}</Text>;
      }
    }
  ];

  const generateNumber = () => {
    return Math.floor(Math.random() * 99).toString();
  };

  return (
    <Modal
      title={<ExactText text={t('Details')} />}
      visible={isViewDetailsVisible}
      footer={null}
      className="exact-modal"
      style={{ top: 20 }}
      onCancel={() => toggleViewDetailsModal()}
    >
      <div style={{ textAlign: 'right', margin: '1vh 5vh 5vh 0vh' }}>
        <Row>
          <Col style={{ textAlign: 'left' }} span={4}>
            <p>
              <Text>{t('Contact Person').toUpperCase()}</Text>
            </p>
            <p>
              <Text>{t('Last Invoice').toUpperCase()}</Text>
            </p>
            <p>
              <Text>{t('Next Invoice').toUpperCase()}</Text>
            </p>
          </Col>
          <Col style={{ textAlign: 'left', fontWeight: 'bold' }} span={5}>
            <p>{getContactPerson(User)}</p>
            <p>{dayjs(last_invoice).format('LL')}</p>
            <p>{dayjs(invoice_date).format('LL')}</p>
          </Col>
          <Col style={{ textAlign: 'left' }} span={4}>
            <p>&nbsp;</p>
            <p>
              <Text>{t('Frequency').toUpperCase()}</Text>
            </p>
            <p>
              <Text>{t('Status').toLocaleUpperCase()}</Text>
            </p>
          </Col>
          <Col style={{ textAlign: 'left', fontWeight: 'bold' }} span={4}>
            <p>&nbsp;</p>
            <p>{frequencyText[frequency]?.toUpperCase()}</p>
            <div className={`exact-status-box ${active}`}>
              {active === true ? t('Active').toUpperCase() : t('Inactive').toUpperCase()}
            </div>
          </Col>
          <Col style={{ marign: 'auto', textAlign: 'right' }} span={6}>
            <br />

            <Button
              type="link"
              onClick={downloadInvoice}
              disabled={store.recurringInvoices.singleState.isExporting}
            >
              <ExportFilledIcon
                style={{ fontSize: '26px', color: '#1D508D' }}
                // disabled={store.recurringInvoices.singleState.isExporting}
              />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        loading={store?.lizenzenInfo?.loading}
        className="exact-table shadow-sm bg-white p-3"
        size="small"
        columns={columns}
        dataSource={[store?.recurringInvoices?.singleState?.Subscription.toJSON()]}
        pagination={false}
      />
      <div style={{ textAlign: 'right', margin: '1vh 5vh 5vh 0vh' }}>{TOTAL()}</div>
    </Modal>
  );
}

export default inject('store')(observer(ViewDetailsModal));
