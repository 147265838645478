import { types } from 'mobx-state-tree';

const LicenseCodesModel = types
  .model('CheckingModel', {
    validateStatus: types.optional(types.string, ''),
    help: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default LicenseCodesModel;
