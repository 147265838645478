import React, { useEffect } from 'react';
import {
  Form,
  Modal,
  Space,
  Button,
  Row,
  Col,
  Select,
  Typography,
  Input,
  InputNumber,
  Menu
} from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

//Ant D
import { DownOutlined } from '@ant-design/icons';
/** APP Imports */
import { ManageLizenzenController } from '@app_modules/ManageLizenzen/controller';
/**CORE IMPORTS */
import { ExactText, FallBackLoaders } from '@core_common/components';
import { TWO_GRID } from '@core_common/antdhelpers/constants';
import { required } from '@core_common/antdhelpers/helperfunctions';

const { Title, Text } = Typography;
const { Option } = Select;

function AddSubscriptionModal({ store, form }) {
  const { t } = useTranslation('common');

  const { toggleAddOrUpdateLicenseModal, handleAddOrUpdateLicense } = ManageLizenzenController({
    store,
    form,
    t
  });

  const { isAddLicenseVisible, isUpdate } = store.lizenzenInfoUtilities;
  const { isCreating } = store.lizenzenInfo;

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  return (
    <Modal
      title={<ExactText text={isUpdate ? t('Update License Plan') : t('Add New License Plan')} />}
      visible={isAddLicenseVisible}
      footer={null}
      className="exact-modal"
      style={{ top: 20 }}
      onCancel={toggleAddOrUpdateLicenseModal}
    >
      <Form
        form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={(values) => handleAddOrUpdateLicense({ values, isUpdate })}
      >
        <div>
          <Row gutter={[16, 0]}>
            <Col {...TWO_GRID}>
              <Form.Item
                className="w-100"
                name="nr"
                label={t('Number')}
                rules={[required(t('Please enter a number'))]}
              >
                <Input className="w-100" placeholder={t('Number')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col {...TWO_GRID}>
              <Form.Item
                className="w-100"
                name="art_der_lizenz"
                label={t('Type of License')}
                rules={[required(t('Please enter type of license'))]}
              >
                <Input className="w-100" placeholder={t('Type of License')} />
              </Form.Item>
            </Col>
            <Col {...TWO_GRID}>
              <Form.Item
                name="monatspreis"
                label={t('Monthly Amount')}
                rules={[required(t('Please enter monthly amount'))]}
              >
                <InputNumber className="w-100" placeholder={t('Monthly amount')} />
              </Form.Item>
            </Col>
            <Col {...TWO_GRID}>
              <Form.Item
                name="anzahl"
                label={t('Max Users')}
                rules={[required(t('Please enter max user'))]}
              >
                <InputNumber className="w-100" placeholder={t('Max Users')} />
              </Form.Item>
            </Col>
            <Col {...TWO_GRID}>
              <Form.Item
                name="jahrespreis"
                label={t('Yearly Amount')}
                rules={[required(t('Please enter yearly amount'))]}
              >
                <InputNumber className="w-100" placeholder={t('Yearly Amount')} />
              </Form.Item>
              <div style={{ textAlign: 'right', float: 'right', marginBottom: '5vh' }}>
                {' '}
                Fr.48 / month
              </div>
            </Col>
          </Row>
        </div>
        <Form.Item>
          <div className="d-flex w-100 justify-content-end">
            <Space>
              <Button onClick={toggleAddOrUpdateLicenseModal} type="default">
                {t('Cancel')}
              </Button>
              <Button loading={isCreating} type="primary" htmlType="submit">
                {t('Submit')}
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default inject('store')(observer(AddSubscriptionModal));
