import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { ExactTitle } from '@core_common/components';
import {
  DashboardHeader,
  ChartSection,
  UserActivityTable
} from '@core_modules/Dashboard/components';
import { Printable } from '@core_modules/Printable/view';

function Dashboard() {
  const { t } = useTranslation('common');

  return (
    <>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ExactTitle level={3} text={t('Dropdown Setting')} />
      </Suspense>
    </>
  );
}

export default Dashboard;
