import React, { Suspense } from 'react';
import { Card, Grid, Row, Col } from 'antd';

import './styles.css';

/** APP IMPORTS */
import { RegisterAccountForm } from '@app_modules/Account/components/';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

import { LayoutController } from '@core_modules/Layout/controller';
import { CompanyLogo } from '@core_modules/Layout/components/SignInLayout';

const { useBreakpoint } = Grid;

function RegisterAccount() {
  const screens = useBreakpoint();

  const { responsiveClass } = LayoutController({});

  return (
    <Suspense fallback={FallBackLoaders.EmptyLoader}>
      <Row className="w-100 h-100 overflow-y">
        <Col md={{ span: 24 }}></Col>
        <Col md={{ span: 24 }} className="w-100 h-100">
          <div className="d-flex align-items-center justify-content-center w-100 ">
            {' '}
            <Card className={`shadow-lg ${responsiveClass(screens)} p-4 my-5 register-form`}>
              <Suspense fallback={FallBackLoaders.EmptyLoader}>
                <CompanyLogo />
              </Suspense>
              <RegisterAccountForm />
            </Card>
          </div>
        </Col>
        <Col md={{ span: 24 }}></Col>
      </Row>
    </Suspense>
  );
}

export default RegisterAccount;
