import React from 'react';
import { ApiOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <ApiOutlined />,
    route: Routes.LIZENZEN_CLIENT_ROUTE,
    menuName: 'Lizenzen'
  },
  {
    key: '4',
    icon: <ApiOutlined />,
    route: Routes.PAYMENT_HISTORY_CLIENT_ROUTE,
    menuName: 'Payment / Invoice History'
  }
];

export default AppMenus;
