import React, { useEffect, Suspense, useState } from 'react';
import { getSnapshot } from 'mobx-state-tree';

import { Button, Modal, Space, Switch, Table, Typography } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
//DAYJS
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

/**APP IMPORTS */
import { LizenzenController } from '@app_modules/Lizenzen/controller';
import { UpdateLicenseCode } from '@app_modules/Lizenzen/components';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { GlobalController } from '@core_modules/Global/Controller';

import './styles.css';

const { Text } = Typography;
const { confirm } = Modal;
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

function LizenzenTable({ store, form, subscriptionStates, setsubscriptionState }) {
  const { t } = useTranslation('common');
  const {
    getSubscriptionByUser,
    getLizenzenInfos,
    onChangePage,
    showUpdateLicenseCodeModal,
    foundLizenzen,
    showConfirm
  } = LizenzenController({
    store,
    form,
    t,
    confirm
  });
  const { search, capitalizeFirstLetter, getContactPerson } = GlobalController({ t });

  useEffect(() => {
    getLizenzenInfos();
    getSubscriptionByUser();
    return () => {};
    // eslint-disable-next-line
  }, [store.subscription.updatedAt]);

  useEffect(() => {
    if (store.subscription.isSendSubscribeMail) {
      store.subscription.SEND_MAIL_SUBSCRIBE({
        subscriptionStates,
        store,
        t,
        setsubscriptionState,
        capitalizeFirstLetter,
        getContactPerson
      });
    }
    return () => {};
    // eslint-disable-next-line
  }, [store.subscription.isSendSubscribeMail, subscriptionStates]);
  const columns = [
    {
      title: t('NR').toUpperCase(),
      dataIndex: 'nr',
      sorter: (a, b) => a.nr.localeCompare(b.nr),
      align: 'center',
      render: (nr) => <>{nr}</>,
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl']
    },
    {
      title: t('ART DER LIZENZ').toUpperCase(),
      dataIndex: 'license_id',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (license_id, record) => (
        <>
          <Space>
            {foundLizenzen(license_id)?.art_der_lizenz}
            <Button
              onClick={() => showUpdateLicenseCodeModal({ record, license_id })}
              size="small"
              style={{ height: '50%', backgroundColor: '#1D508D', color: 'white' }}
            >
              ...
            </Button>
          </Space>
        </>
      )
    },
    {
      title: t('ANZAHL').toUpperCase(),
      dataIndex: 'number',
      align: 'center',

      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl']
    },

    {
      title: t('STATUS').toUpperCase(),
      dataIndex: 'Status',
      responsive: ['xl'],
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (row) => {
        return <Text>{t(row?.status)}</Text>;
      }
    },
    {
      title: t('NEXT CHARGE').toUpperCase(),
      dataIndex: 'RecurringInvoices',
      responsive: ['xl'],
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (RecurringInvoices, record) => {
        const dateNow = dayjs();
        const daysLeft = dayjs(RecurringInvoices?.invoice_date).add(10, 'day').diff(dateNow, 'day');
        const nextCharge = dayjs(RecurringInvoices?.invoice_date)
          .add(10, 'day')
          .format('DD.MMMM.YYYY');

        return (
          <Text>
            {nextCharge} ({daysLeft} {t('days left')})
          </Text>
        );
      }
    },

    {
      title: t('Action').toUpperCase(),
      dataIndex: 'status_id',
      responsive: ['lg', 'xl'],
      align: 'center',
      shouldCellUpdate: (prev, next) =>
        JSON.stringify(prev.status_id) !== JSON.stringify(next.status_id),
      render: (status_id, record) => {
        let NOW = new Date();

        const values = { status_id: record.status_id === 1 ? 2 : 1, cancellation_date: NOW };

        const updateId = record.id;
        return (
          <>
            {status_id !== 2 ? (
              <Button
                type="primary"
                style={{ backgroundColor: status_id !== 2 ? '#c22828' : '#22a052', border: '0px' }}
                onClick={(e) => {
                  showConfirm({ values, updateId });
                  //toggleUnsubscribe({ values, isUpdate: true, updateId });
                }}
              >
                {t('UNSUBSCRIBE')}
              </Button>
            ) : (
              <> </>
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <Table
        loading={store?.subscription?.loading}
        className="exact-table shadow-sm bg-white p-3"
        size="small"
        columns={columns}
        dataSource={store?.subscription?.state
          ?.toJSON()
          .filter((e) => search(['purchase_date'], e)(store.subscriptionUtilities.searchKeyword))}
        pagination={{
          position: ['bottomCenter'],
          hideOnSinglePage: true,
          pageSize: 10,
          responsive: true,
          onChange: onChangePage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} subscriptions`,
          // pageSizeOptions: [5, 10, 15, 20],
          showSizeChanger: false,
          total: store.subscription.toJSON().total
        }}
      />

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <UpdateLicenseCode form={form} />
      </Suspense>
    </>
  );
}

export default inject('store')(observer(LizenzenTable));
