import React, { Suspense } from 'react';
import { Button, Col, Input, Row, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import { PlusOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**COMPONENTS */

/**APP IMPORTS */
import { AddUpdateModal } from '@app_modules/ManageLizenzen/components';
import { ManageLizenzenController } from '@app_modules/ManageLizenzen/controller';

/**CORE IMPORTS */
import { ExactTitle, FallBackLoaders } from '@core_common/components';
import { ANTD_HALF_COL } from '@core_common/antdhelpers/constants';

function LizenzenHeader({ store, form }) {
  const { t } = useTranslation('common');
  const { handleSearch, toggleAddOrUpdateLicenseModal } = ManageLizenzenController({
    store,
    form,
    t
  });
  const title = () => {
    return (
      <div>
        {t('Admin Setting')} &nbsp;&nbsp; &gt; &nbsp;&nbsp;
        <span style={{ color: '#404040', fontSize: '14px', fontWeight: 'normal !important' }}>
          Manage Lizenzen
        </span>
      </div>
    );
  };
  return (
    <>
      <Row>
        <Col md={{ span: ANTD_HALF_COL }}>
          <ExactTitle level={3} text={title()} />
        </Col>
        <Col
          className="d-flex w-100 justify-content-end align-items-center"
          md={{ span: ANTD_HALF_COL }}
        >
          <Space className="mb-3">
            <Button
              onClick={toggleAddOrUpdateLicenseModal}
              className="shadow-sm"
              type="default"
              icon={<PlusOutlined />}
            />

            <Input.Search
              className="shadow-sm"
              onSearch={handleSearch}
              id="exact-search"
              placeholder={t('Search')}
              enterButton={t('Search')}
            />
          </Space>
        </Col>
      </Row>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <AddUpdateModal form={form} />
      </Suspense>
    </>
  );
}

export default inject('store')(observer(LizenzenHeader));
