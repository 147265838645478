import { types, flow } from 'mobx-state-tree';
import UsersModel from './UsersModel';
import axios from 'axios';
import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';

const API_PATH = '/api/users';
const API_PATH_REGISTER = '/api/register';
const API_PATH_CR_CUSTOMER_INFO = '/api/customer-info';

export default types
  .model('UsersState', {
    state: types.optional(types.array(UsersModel), []),
    single: types.optional(UsersModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false),
    updatedAt: types.optional(types.string, '')
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),
    setSingleState(record) {
      self.single = Object.assign(record);
    },
    REGISTER: flow(function* (values) {
      try {
        self.isCreating = true;
        const { data } = yield axios.post(API_PATH_REGISTER, values);
        const user = {
          user_id: data.user_id
        };
        // const { data: customerInfoData } = yield axios.post(API_PATH_CR_CUSTOMER_INFO, user);

        self.state.push(data);
        self.isCreating = false;

        return [{ message: 'Created!', data }, null];
      } catch (error) {
        self.isCreating = false;
        return [null, error];
      }
    }),

    SEND_EMAIL: flow(function* (payload) {
      try {
        let response = yield axios.post(`${API_PATH}/send_email`, payload);
        return [{ message: response.data.data.message }, null];
      } catch (error) {
        return [null, error];
      }
    })
  }))
  .views((self) => ({}));
