import React from 'react';
import { ApiOutlined, MoneyCollectOutlined, PaperClipOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

import { useTranslation } from 'react-i18next';

const SideBarMenusControlller = () => {
  const { t } = useTranslation('common');
  const AdminMenus = [
    {
      key: 'manage-lizenzen',
      icon: <ApiOutlined />,
      route: Routes.MANAGE_LIZENZEN,
      menuName: 'Manage Lizenzen'
    },
    {
      key: 'recurring-invoice',
      icon: <PaperClipOutlined />,
      route: Routes.RECURRING_INVOICE,
      menuName: 'Recurring Invoice'
    },
    {
      key: 'payment-history',
      icon: <MoneyCollectOutlined />,
      route: Routes.PAYMENT_HISTORY_ADMIN_ROUTE,
      menuName: 'Payment / Invoice History'
    }
  ];

  return { AdminMenus };
};

export default SideBarMenusControlller;
