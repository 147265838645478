import { types, flow } from 'mobx-state-tree';
import { StatusModel, SubscriptionModel } from '@app_modules/Lizenzen/model';

const InvoiceModel = types
  .model('InvoiceModel', {
    id: types.optional(types.number, 0),
    key: types.optional(types.number, 0),
    Status: types.optional(StatusModel, {}),
    Subscriptions: types.optional(types.array(SubscriptionModel), []),
    subscription_id: types.optional(types.number, 0),
    invoice_nr: types.optional(types.string, ''),
    invoice_date: types.optional(types.string, ''),
    due_date: types.optional(types.string, ''),
    date_paid: types.optional(types.maybeNull(types.string), ''),
    timestamp: types.optional(types.string, ''),
    status_id: types.optional(types.number, 0),
    active: types.optional(types.boolean, true),
    isExporting: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setIsExport(TrueOrFalse) {
      self.isExporting = TrueOrFalse;
    }
  }));

export default InvoiceModel;
