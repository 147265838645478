import React, { useEffect, Suspense } from 'react';
//ANT D
import { EyeFilled } from '@ant-design/icons';
import { Button, Modal, Tooltip, Table, Typography, Switch, Space } from 'antd';

import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
//DAYJS
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

/**APP IMPORTS */
import { RecurringInvoiceController } from '@app_modules/RecurringInvoice/controller';
import { ViewDetailsModal, ViewProfileModal } from '@app_modules/RecurringInvoice/components';
import { LizenzenController } from '@app_modules/Lizenzen/controller';
import { PaymentHistoryController } from '@app_modules/PaymentHistory/controller';

import { compare } from '@app_data/utils';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

const { Text } = Typography;
const { confirm } = Modal;
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

function RecurringInvoiceTable({ store, form }) {
  const { t } = useTranslation('common');
  const {
    getRecurringInvoice,
    handleChangeStatus,
    toggleViewDetailsModal,
    toggleViewProfileModal,
    computeTotal,
    onChangePage
  } = RecurringInvoiceController({
    store,
    t
  });

  const { getLizenzenInfos } = LizenzenController({ store, t });

  useEffect(() => {
    getLizenzenInfos();
    getRecurringInvoice();
    return () => {};
    // eslint-disable-next-line
  }, [store.recurringInvoices.updatedAt]);

  const columns = [
    {
      title: t('Id').toUpperCase(),
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl']
    },
    {
      title: t('Date').toUpperCase(),
      dataIndex: 'timestamp',
      sorter: (a, b) => a.timestamp - b.timestamp,
      align: 'center',
      responsive: ['xl'],
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (timestamp) => {
        return <>{dayjs(timestamp).format('DD.MM.YYYY')} </>;
      }
    },
    {
      title: t('Profile Name').toUpperCase(),
      dataIndex: 'User',
      sorter: (a, b) => {
        const type = a.User.employment;

        const Employment = {
          1: a.User.contact_person.localeCompare(b.User.contact_person),
          2: a.User.first_name.localeCompare(b.User.first_name)
        };
        return Employment[type];
      },
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (User, record) => {
        const type = User.employment;

        const Employment = {
          1: `${User.company_name}`,
          2: `${User.first_name} ${User.last_name}`
        };
        return (
          <>
            {' '}
            <Space>
              {Employment[type]}{' '}
              <Button
                onClick={() => toggleViewProfileModal(record)}
                size="small"
                style={{ height: '50%', backgroundColor: '#1D508D', color: 'white' }}
              >
                ...
              </Button>{' '}
            </Space>
          </>
        );
      }
    },
    {
      title: t('Contact Person').toUpperCase(),
      dataIndex: 'User',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      align: 'center',
      sorter: (a, b) => {
        const type = a.User.employment;

        const Employment = {
          1: a.User.contact_person.localeCompare(b.User.contact_person),
          2: a.User.first_name.localeCompare(b.User.first_name)
        };
        return Employment[type];
      },
      render: (User, record) => {
        const type = User.employment;

        const Employment = {
          1: `${User.contact_person}`,
          2: `${User.first_name} ${User.last_name}`
        };
        return <>{Employment[type]} </>;
      }
    },
    {
      title: t('Last Invoice').toUpperCase(),
      dataIndex: 'last_invoice',
      align: 'center',
      sorter: (a, b) => a.last_invoice - b.last_invoice,
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (last_invoice) => {
        return <> {!!last_invoice && dayjs(last_invoice).format('DD.MMMM.YYYY')}</>;
      }
    },

    {
      title: t('Next Invoice').toUpperCase(),
      dataIndex: 'invoice_date',
      align: 'center',
      responsive: ['xl'],
      sorter: (a, b) => a.invoice_date.localeCompare(b.invoice_date),
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (invoice_date) => {
        return <> {dayjs(invoice_date).format('DD.MMMM.YYYY')}</>;
      }
    },
    {
      title: t('Frequency').toUpperCase(),
      dataIndex: 'frequency',
      align: 'center',
      responsive: ['xl'],
      sorter: (a, b) => a.frequency - b.frequency,
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (number) => {
        const frequencyText = {
          1: t('1 month'),
          2: t('12 months')
        };
        return <>{frequencyText[number].toUpperCase()} </>;
      }
    },
    {
      title: t('Total Price').toUpperCase(),
      dataIndex: 'Subscription',
      align: 'center',
      responsive: ['xl'],
      sorter: (a, b) => {
        return computeTotal(a.Subscription) - computeTotal(b.Subscription);
      },
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (Subscription) => {
        return <>{computeTotal(Subscription)} </>;
      }
    },

    {
      title: t('Status').toUpperCase(),
      dataIndex: 'active',
      align: 'center',
      responsive: ['xl'],
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (isActive, record) => {
        return (
          <Switch
            size="small"
            style={{ backgroundColor: isActive ? 'green' : 'red' }}
            defaultChecked={isActive ? true : false}
            checkedChildren={t('active')}
            unCheckedChildren={t('inactive')}
            onChange={async (active) => await handleChangeStatus({ active, record })}
          />
        );
      }
    },

    {
      title: t('Action').toUpperCase(),
      dataIndex: 'id',
      align: 'center',
      responsive: ['lg', 'xl'],
      render: (status_id, record) => {
        return (
          <Tooltip title={t('View')}>
            <Button
              icon={<EyeFilled style={{ color: '#1D508D' }} />}
              onClick={() => toggleViewDetailsModal(record)}
              type="link"
            />
          </Tooltip>
        );
      }
    }
  ];

  return (
    <>
      <Table
        loading={store?.recurringInvoices?.loading}
        className="exact-table shadow-sm bg-white p-3"
        size="small"
        columns={columns}
        dataSource={store?.recurringInvoices?.state?.toJSON().sort(compare)}
        pagination={{
          position: ['bottomCenter'],
          hideOnSinglePage: true,
          pageSize: 10,
          responsive: true,
          onChange: onChangePage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} invoice`,
          // pageSizeOptions: [5, 10, 15, 20],
          showSizeChanger: false,
          total: store.recurringInvoices.total
        }}
      />

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ViewDetailsModal form={form} />
      </Suspense>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ViewProfileModal form={form} />
      </Suspense>
    </>
  );
}

export default inject('store')(observer(RecurringInvoiceTable));
