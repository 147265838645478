import React, { Suspense } from 'react';
import { Row, Modal, Button, Col, Table, Typography, Input, Dropdown, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

//DAYJS
import dayjs from 'dayjs';
//Ant D
import { EditFilled, default as Icon, CloseSquareOutlined } from '@ant-design/icons';

/** APP Imports */
import { ExportFilled } from '@app_assets/svg/CustomIcon';
import { PaymentHistoryController } from '@app_modules/PaymentHistory/controller';
import { RecurringInvoiceController } from '@app_modules/RecurringInvoice/controller';

/**CORE IMPORTS */

import { ExactText, FallBackLoaders, ExactTitle } from '@core_common/components';

const { Title, Text } = Typography;

function ViewDetailsModal({ store, form }) {
  const [datePaidEdit, setDatePaidEdit] = React.useState(false);

  const { t } = useTranslation('common');
  const ExportFilledIcon = (props) => <Icon component={ExportFilled} {...props} />;
  const {
    toggleViewDetailsModal,
    foundLizenzen,
    computeTotal,
    downloadInvoice,
    handleUpdateDatePaid
  } = PaymentHistoryController({
    store,
    form,
    t
  });

  const { id, invoice_date, due_date, date_paid, Status } = store.invoice.singleState;

  const { isViewDetailsVisible } = store.invoiceUtilities;
  const plan = {
    1: {
      text: t('Monthly'),
      name: 'monatspreis',
      price: 1
    },
    2: {
      text: t('Yearly'),
      name: 'jahrespreis',
      price: 2
    }
  };

  const columns = [
    {
      title: t('Invoice Nr').toUpperCase(),
      dataIndex: 'id',
      align: 'center',
      render: (text) => <>{store?.invoice?.invoice_nr}</>,
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl']
    },
    {
      title: t('ART DER LIZENZ').toUpperCase(),
      dataIndex: 'license_id',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (license_id) => <>{foundLizenzen(license_id).art_der_lizenz}</>
    },
    {
      title: t('ANZAHL').toUpperCase(),
      dataIndex: 'number',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl']
    },

    {
      title: t('PLAN').toUpperCase(),
      dataIndex: 'payment_type',
      align: 'center',
      responsive: ['xl'],
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (payment_type, record) => {
        const plan_name = plan[payment_type].text;
        const plan_type = plan[payment_type].name;

        return (
          <>
            <strong> {plan_name.toUpperCase()} </strong> <br />
            Fr {foundLizenzen(record.license_id)[plan_type]} <br />
            {payment_type === 2 ? (
              <span style={{ fontSize: '9px' }}>
                Fr {(foundLizenzen(record.license_id)[plan_type] / 12).toFixed(1)}/{t('mo')}{' '}
              </span>
            ) : (
              ''
            )}
          </>
        );
      }
    },
    {
      title: t('TOTAL PREIS').toUpperCase(),
      dataIndex: 'payment_type',
      align: 'center',
      responsive: ['xl'],
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (payment_type, record) => {
        const plan_type = plan[payment_type].name;
        return <Text> {foundLizenzen(record.license_id)[plan_type] * record.number}</Text>;
      }
    }
  ];

  const toggleDatePaidEdit = () => {
    setDatePaidEdit((prevState) => !prevState);
  };

  const TOTAL = () => {
    const SubscriptionsArray = store?.invoice?.singleState?.Subscriptions;
    const TOTAL = computeTotal(SubscriptionsArray);
    return <ExactTitle text={`${t('Total Fr.')} ${TOTAL}`} level={3} />;
  };
  const now = dayjs().format('YYYY-MM-DD'); //2017-04-20
  console.log('🚀 ~ file: ViewDetailsModal.js ~ line 128 ~ ViewDetailsModal ~ now', now);
  return (
    <Modal
      title={<ExactText text={t('Details')} />}
      visible={isViewDetailsVisible}
      footer={null}
      className="exact-modal"
      style={{ top: 20 }}
      onCancel={() => {
        toggleViewDetailsModal(null);
        setDatePaidEdit(false);
      }}
    >
      <div style={{ textAlign: 'right', margin: '1vh 5vh 5vh 0vh' }}>
        <Row>
          <Col style={{ textAlign: 'left' }} span={6}>
            <p>
              <Text>{t('Invoice Nr').toUpperCase()}</Text>
            </p>
            <p>
              <Text>{t('Invoice Date').toUpperCase()}</Text>
            </p>
            <p>
              <Text>{t('Due Date').toUpperCase()}</Text>
            </p>
          </Col>
          <Col style={{ textAlign: 'left', fontWeight: 'bold' }} span={6}>
            <p>{id} </p>
            <p>{dayjs(invoice_date).format('LL')}</p>
            <p>{dayjs(due_date).format('LL')}</p>
          </Col>
          <Col style={{ textAlign: 'left' }} span={5}>
            <p>
              <Text>{t('Date Paid').toUpperCase()}</Text>{' '}
            </p>
            <p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text>{t('Status').toLocaleUpperCase()}</Text>{' '}
            </p>
          </Col>

          <Col style={{ textAlign: 'left', fontWeight: 'bold' }} span={5}>
            <p>
              {date_paid && !datePaidEdit ? (
                <div style={{ marginTop: '-0.9vh' }}>
                  {dayjs(date_paid).format('LL')}
                  {store.login.user_type_id === 1 ? (
                    <Button onClick={toggleDatePaidEdit} icon={<EditFilled />} type="link" />
                  ) : (
                    <> &nbsp; </>
                  )}
                </div>
              ) : store.login.user_type_id === 1 ? (
                <div style={{ marginTop: '-0.9vh' }}>
                  <input
                    onChange={handleUpdateDatePaid}
                    style={{ border: 'none', outline: 'none' }}
                    type="date"
                    max={dayjs().subtract(1, 'day').format('YYYY-MM-DD')}
                  />
                  {!date_paid || (
                    <Button
                      onClick={toggleDatePaidEdit}
                      icon={<CloseSquareOutlined />}
                      type="link"
                      style={{ color: 'red' }}
                    />
                  )}
                </div>
              ) : (
                <> &nbsp; </>
              )}
            </p>
            <p className={`exact-status-box ${Status.status}`}>{t(Status.status)}</p>
          </Col>

          <Col style={{ marign: 'auto', textAlign: 'right' }} span={2}>
            <Button
              type="link"
              icon={<ExportFilledIcon style={{ fontSize: '26px', color: '#1D508D' }} />}
              onClick={() => downloadInvoice(null)}
              loading={store.invoice.singleState.isExporting}
            />
            {/* <ExportFilledIcon style={{ fontSize: '26px', color: '#1D508D' }} />
            </Button> */}
          </Col>
        </Row>
      </div>
      <Table
        loading={store?.lizenzenInfo?.loading}
        className="exact-table exact-center shadow-sm bg-white p-3"
        size="small"
        columns={columns}
        dataSource={store?.invoice?.singleState?.Subscriptions.toJSON()}
        pagination={false}
      />
      <div style={{ textAlign: 'right', margin: '1vh 5vh 5vh 0vh' }}>{TOTAL()}</div>
    </Modal>
  );
}

export default inject('store')(observer(ViewDetailsModal));
