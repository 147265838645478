export const ExportFilled = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 225.000000 225.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        fill="currentColor"
        d="M525 2098 c-16 -6 -45 -26 -62 -44 l-33 -33 0 -897 0 -897 39 -37
 c21 -20 53 -40 71 -44 18 -3 314 -6 659 -6 604 0 701 4 701 30 0 6 6 10 13 10
 7 0 20 13 30 29 16 27 17 79 17 610 l0 581 -290 0 c-272 0 -293 1 -330 20 -75
 38 -74 37 -80 380 l-5 305 -350 2 c-228 1 -360 -2 -380 -9z m958 -1186 l207
 -207 -215 -215 -215 -215 0 143 0 142 -210 0 -210 0 0 145 0 145 210 0 210 0
 0 135 c0 74 3 135 8 135 4 0 101 -93 215 -208z"
      />
      <path
        fill="currentColor"
        d="M1412 1824 l3 -269 275 -2 c221 -2 271 0 253 10 -12 6 -22 15 -23 19
 -1 12 -120 125 -125 120 -3 -3 -5 0 -5 6 0 10 -263 279 -348 355 l-32 29 2
 -268z"
      />
    </g>
  </svg>
);

export const UnsubcribeFilled = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200.000000 200.000000"
    preserveAspectRatio="xMidYMid meet"
    width="1em"
    height="1em"
  >
    <g
      transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        fill="currentColor"
        d="M186 1984 c-34 -11 -65 -31 -102 -68 -88 -88 -85 -64 -82 -777 3
-612 3 -614 25 -655 28 -52 99 -116 150 -134 29 -10 122 -15 380 -17 306 -4
346 -2 378 13 60 28 82 97 49 153 -33 56 -32 56 -399 59 -222 2 -344 7 -352
14 -10 8 -13 128 -13 589 0 566 0 579 20 599 20 20 33 20 593 20 537 0 575 -1
590 -18 14 -15 16 -62 19 -352 3 -362 3 -361 59 -394 56 -33 125 -11 153 49
15 32 17 73 14 378 l-3 342 -28 57 c-30 61 -64 95 -131 131 -41 22 -43 22
-656 24 -525 2 -622 0 -664 -13z"
      />
      <path
        fill="currentColor"
        d="M510 1542 c-43 -21 -74 -78 -64 -119 4 -17 50 -72 117 -140 l111
-113 -111 -112 c-72 -75 -112 -123 -115 -142 -18 -95 75 -167 160 -122 15 8
72 60 127 115 l100 101 95 -95 c76 -75 104 -97 131 -101 73 -12 137 52 125
125 -4 27 -26 55 -101 131 l-95 95 101 100 c55 55 107 112 115 127 45 85 -27
178 -122 160 -19 -3 -67 -43 -142 -116 l-113 -110 -107 107 c-92 91 -140 127
-170 127 -4 0 -23 -8 -42 -18z"
      />
      <path
        fill="currentColor"
        d="M1282 765 c-22 -10 -38 -27 -48 -51 -15 -35 -14 -44 26 -213 59 -246
84 -322 117 -353 51 -47 62 -45 138 32 l70 70 121 -121 c105 -105 126 -121
160 -125 75 -9 144 64 130 138 -5 25 -34 61 -126 153 l-120 120 71 71 c63 64
71 75 65 99 -13 53 -41 65 -307 131 -140 35 -256 63 -259 63 -3 0 -20 -6 -38
-14z"
      />
    </g>
  </svg>
);
