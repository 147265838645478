import { types } from 'mobx-state-tree';

const LizenzenModel = types
  .model('LizenzenModel', {
    key: types.optional(types.number, 0),
    nr: types.optional(types.number, 0),
    art_der_lizenz: types.optional(types.string, ''),
    anzahl: types.optional(types.number, 1),
    status: types.optional(types.number, 0),
    next_charge: types.optional(types.string, ''),
    aktiv: types.optional(types.boolean, true),
    updatedAt: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default LizenzenModel;
