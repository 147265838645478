import React, { Suspense, useState } from 'react';
import { Form } from 'antd';

import { useTranslation } from 'react-i18next';

/**APP IMPORTS */
import {
  RecurringInvoiceHeader,
  RecurringInvoiceTable
} from '@app_modules/RecurringInvoice/components/';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

function RecurringInvoice() {
  const [form] = Form.useForm();

  return (
    <>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <RecurringInvoiceHeader form={form} />
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <RecurringInvoiceTable form={form} />
      </Suspense>
    </>
  );
}

export default RecurringInvoice;
