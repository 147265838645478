import { types } from 'mobx-state-tree';

const AddSubscriptionModel = types
  .model('AddSubscriptionModel', {
    id: types.optional(types.number, 0),
    nr: types.optional(types.string, ''),
    art_der_lizenz: types.optional(types.string, ''),
    key: types.optional(types.number, 0),
    license_id: types.optional(types.number, 0),
    user_id: types.optional(types.string, ''),
    number: types.optional(types.number, 0),
    anzahl: types.optional(types.number, 0),
    payment_type: types.optional(types.number, 1),
    monatspreis: types.optional(types.number, 0),
    jahrespreis: types.optional(types.number, 0),
    aktiv: types.optional(types.boolean, true)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default AddSubscriptionModel;
