import React from 'react';
import './styles.css';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
/** Core Imports */
import { Error404Controller } from '@core_modules/Error404/controller';

function Error404({ store }) {
  const { handleRedirect } = Error404Controller({ store });

  const { t } = useTranslation('common');
  return (
    <div className="no-print">
      <Result
        status="404"
        title="404"
        subTitle={t('Sorry, the page you visited does not exist')}
        extra={
          <Link to={handleRedirect}>
            <Button type="primary">{t('Back Home')}</Button>
          </Link>
        }
      />
    </div>
  );
}

export default inject('store')(Error404);
