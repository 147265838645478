import dayjs from 'dayjs';

const PaymentHistoryController = ({ store, form, t }) => {
  const plan = {
    1: {
      text: t('Monthly'),
      textPDF: t('Monatslizenzen'),
      name: 'monatspreis',
      price: 1
    },
    2: {
      text: t('Yearly'),
      textPDF: t('Jahreslizenzen'),
      name: 'jahrespreis',
      price: 2
    }
  };

  const getContactPerson = (User) => {
    const type = User?.employment;

    const Employment = {
      1: `${User?.company_name}`,
      2: `${User?.first_name} ${User?.last_name}`
    };
    return Employment[type];
  };
  const getRecurringInvoiceByUser = async () => {
    const user_id = store.login.id;

    let params = { user_id };
    await store.invoice.GET_RECURRING_INVOICE_BY_USER(params);
  };

  const getRecurringInvoices = async () => {
    const page = 1;

    const search = store.invoiceUtilities.search;
    const props = store.invoiceUtilities.props;
    let params = { page, search, props };

    await store.invoice.LIST(params);
  };

  const handleUpdateDatePaid = async (e) => {
    const _date = new Date(e.target.value);

    const [success, err] = await store.invoice.UPDATE(Number(store.invoice.singleState.id), {
      date_paid: _date.toISOString(),
      status_id: 4
    });

    if (success) {
      store.invoice.setInvoiceSingleState(success.data);
    }
  };

  const handleSearch = async (search) => {
    search = search.trim();
    const props = store.invoiceUtilities.props;
    let params = { search, props };

    store.invoiceUtilities.setSearch(search);
    await store.invoice.LIST(params);
  };

  const onChangePage = () => {};

  const toggleViewDetailsModal = (invoiceData) => {
    console.log(
      '🚀 ~ file: PaymentHistoryController.js ~ line 70 ~ toggleViewDetailsModal ~ invoiceData',
      invoiceData
    );
    store.invoiceUtilities.toggleViewDetailsModal();
    const openViewDetailsModal = invoiceData !== null;
    if (openViewDetailsModal) {
      store.invoice.setInvoiceSingleState(invoiceData?.toJSON());
      store.invoice.setInvoiceNr(invoiceData?.id);
    }
  };
  const foundLizenzen = (license_id) => {
    const found = store.lizenzenInfo.state.find((element) => element.id === license_id);
    return found;
  };

  const computeTotal = (Subscriptions) => {
    let total = 0;

    Subscriptions?.map((subs) => {
      const Lizenzen = foundLizenzen(subs?.license_id);
      if (subs?.payment_type === 1) {
        total = subs?.number * Lizenzen?.monatspreis + total;
      }
      if (subs?.payment_type === 2) {
        total = total + subs?.number * Lizenzen?.jahrespreis;
      }
      return total;
    });
    return total.toFixed(2);
  };

  const downloadInvoice = async (invoiceData) => {
    const Subscriptions = [];
    let singleInvoice = [];
    let subtotal = 0;
    let contact_person = {};
    let nr = 9999;
    // Generate the table
    if (invoiceData) {
      invoiceData.setIsExport(true); //disable export button
      singleInvoice = invoiceData.Subscriptions;
      contact_person = await getContactPerson(invoiceData.User);
      nr = invoiceData.id;
    }
    if (!invoiceData) {
      store.invoice.singleState.setIsExport(true); //disable export button
      singleInvoice = store.invoice.singleState.Subscriptions;
      contact_person = await getContactPerson(store.invoice.singleState.User);
      nr = store.invoice.singleState.id;
    }

    singleInvoice.map((subscription) => {
      const plan_type = plan[subscription.payment_type].name;
      const plan_name = plan[subscription.payment_type].textPDF;
      const lizenzenText = t('lizenzen');
      subtotal = subtotal + foundLizenzen(subscription.license_id)[plan_type] * subscription.number;
      Subscriptions.push({
        art_der_lizenz: foundLizenzen(subscription.license_id).art_der_lizenz,
        nr,
        purchase_date: dayjs(subscription.purchase_date).format('DD.MM.YYYY'),
        end_date: dayjs(subscription.end_date).format('DD.MM.YYYY'),
        number: subscription.number,
        total: foundLizenzen(subscription.license_id)[plan_type] * subscription.number,
        plan_name,
        price: foundLizenzen(subscription.license_id)[plan_type],
        per_price:
          subscription.payment_type === 2 &&
          (foundLizenzen(subscription.license_id)[plan_type] / 12).toFixed(1)
      });
      return Subscriptions;
    });
    const [success, error] = await store.invoice.downloadInvoice(
      Subscriptions,
      subtotal,
      contact_person,
      invoiceData
    );
    if (invoiceData) {
      invoiceData.setIsExport(false); //enable export button
    } else {
      store.invoice.singleState.setIsExport(false); //enable export button
    }

    return;
  };

  return {
    downloadInvoice,
    computeTotal,
    getRecurringInvoiceByUser,
    onChangePage,
    handleSearch,
    toggleViewDetailsModal,
    foundLizenzen,
    getRecurringInvoices,
    handleUpdateDatePaid
  };
};
export default PaymentHistoryController;
