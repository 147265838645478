const Error404Controller = ({ store }) => {
  const handleRedirect = () => {
    if (store.login.user_type_id === 1) {
      return '/';
    }
    if (store.login.user_type_id === 2) {
      return '/cl/lizenzen';
    }
    return '/login';
  };

  return { handleRedirect };
};

export default Error404Controller;
