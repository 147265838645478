

export const compare = ( a, b ) => {
  
  if ( a.User.first_name < b.User.first_name ){
    return -1;
  }
  if ( a.User.first_name > b.User.first_name ){
    return 1;
  }
  return 0;
}