import { types, flow } from 'mobx-state-tree';
import axios from 'axios';
import CheckingModel from './CheckingModel';
const apiKeyPath = '/api/key-normal/find-license-code';

const LicenseCodesModel = types
  .model('LicenseCodesModel', {
    id: types.optional(types.number, 0),
    key: types.optional(types.number, 0),
    license_code: types.optional(types.string, ''),
    comment: types.optional(types.string, ''),
    timestamp: types.optional(types.string, ''),
    checking: types.optional(CheckingModel, {}),
    prevStateUpdatedAt: types.optional(types.number, 0),
    stateUpdatedAt: types.optional(types.number, 0),
    number: types.optional(types.number, 0)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setValidation(validateStatus = '', help = '') {
      const newStatus = { validateStatus: validateStatus, help: ' ' };
      self.checking = Object.assign(self.checking, newStatus);
      self.prevStateUpdatedAt = self.stateUpdatedAt;
      self.stateUpdatedAt = new Date().getTime();
    },
    updateLicenseCode(value) {
      self.license_code = value;
    },
    updateComment(value) {
      self.comment = value;
    },
    findLicense: flow(function* () {
      const valid_key = self.license_code;
      const params = { valid_key };
      try {
        const { data } = yield axios.get(`${apiKeyPath}`, { params });
        return [data, null];
      } catch (error) {
        return [null, error];
      }
    })
  }));

export default LicenseCodesModel;
