import { flow, types } from 'mobx-state-tree';

import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
// MODEL
import RecurringInvoiceModel from './RecurringInvoiceModel';

import axios from 'axios';
//DAYJS
import dayjs from 'dayjs';

const API_PATH = '/api/recurring-invoice';

export default types
  .model('RecurringInvoiceState', {
    state: types.optional(types.array(RecurringInvoiceModel), []),
    singleState: types.optional(RecurringInvoiceModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false),
    updatedAt: types.optional(types.string, ''),
    invoice_nr: types.optional(types.number, 99999)
  })
  .actions((self) => ({
    setSingleState(record) {
      const Subscriptions = {
        ...record,
        key: record.id
      };

      self.singleState = Object.assign({ ...record, Subscriptions });
    },
    setInvoiceNr(invoice_nr) {
      self.invoice_nr = invoice_nr;
    },
    downloadInvoice: flow(function* (Subscriptions, subtotal, contact_person) {
      const date_now = dayjs().format('LL');
      const invoice_date = dayjs(self.singleState.invoice_date).format('DD.MM.YYYY');
      const payable_until = dayjs(self.singleState.invoice_date)
        .add(30, 'day')
        .format('DD.MM.YYYY');
      const mwst = subtotal * 0.077;
      const betrag_mwst = mwst + subtotal;

      try {
        const options = [
          {
            ...self.singleState,
            date_now: date_now,
            Subscriptions,
            subtotal: subtotal,
            invoice_date,
            business_unit_name: 'mypdfokkk',
            contact_person,
            payable_until,
            betrag_mwst,
            mwst: mwst
          }
        ];
        const { data } = yield axios.post(`/api/download/invoice/`, ...options);
        // Opens new tab to preview and download
        window.open(data, '_blank');
        return [data, null];
      } catch (error) {
        return [null, error];
      }
    }),
    ...ApplyRootCrud(API_PATH, self)
  }))
  .views((self) => ({}));
