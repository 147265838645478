import { types, flow } from 'mobx-state-tree';
import UserTypesModel from './UserTypesModel';
import CustomerInfoModel from './CustomerInfoModel';

import InvoiceModel from './InvoiceModel';
import axios from 'axios';

//DAYJS
import dayjs from 'dayjs';

const API_PATH = '/api/recurring-invoice';

const UsersModel = types
  .model('UsersModel', {
    id: types.optional(types.string, ''),
    key: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    user_type_id: types.optional(types.number, 0),
    employment: types.optional(types.number, 2),
    company_name: types.optional(types.string, ''),
    phone_number: types.optional(types.maybeNull(types.string), ''),
    zip_code: types.optional(types.maybeNull(types.string), ''),
    street_nr: types.optional(types.maybeNull(types.string), ''),
    country: types.optional(types.maybeNull(types.string), ''),
    city: types.optional(types.maybeNull(types.string), ''),
    additional_address: types.optional(types.maybeNull(types.string), ''),
    contact_person: types.optional(types.maybeNull(types.string), ''),
    honorific: types.optional(types.maybeNull(types.string), ''),
    Type: types.optional(UserTypesModel, {}),
    active: types.optional(types.boolean, true),
    Invoices: types.optional(types.maybeNull(types.array(InvoiceModel)), []),
    CustomerInfo: types.optional(types.maybeNull(CustomerInfoModel), {})
  })
  .views((self) => ({
    get name() {
      return self.first_name + ' ' + self.last_name;
    }
  }))
  .actions((self) => ({
    SET_STATE(values) {
      self = {
        ...self,
        ...values
      };
    },
    SEND_INVOICE: flow(function* ({ userDataForExport, Subscriptions, subtotal, contact_person }) {
      const date_now = dayjs().format('LL');
      const invoice_date = dayjs(self.invoice_date).format('DD.MM.YYYY');
      const payable_until = dayjs(self.invoice_date).add(30, 'day').format('DD.MM.YYYY');
      const mwst = subtotal * 0.077;
      const betrag_mwst = (mwst + subtotal).toFixed(2);
      const invoice_dateTEXT = dayjs(self.invoice_date).format('LL');

      try {
        let { data } = yield axios.post(`${API_PATH}/sendmail-invoice`, {
          ...userDataForExport,
          invoice_dateTEXT,
          date_now,
          hasPDF: true, // for mailer to attached PDF
          invoice_date,
          payable_until,
          mwst,
          betrag_mwst,
          Subscriptions,
          subtotal,
          contact_person
        });
        return [data, null];
      } catch (error) {
        return [null, error];
      }
    })
  }));

export default UsersModel;
