import React, { useEffect, Suspense } from 'react';

import { Button, Divider, Space, Switch, Table, Typography } from 'antd';
import { default as Icon, EyeFilled, ExportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
/**APP IMPORTS */
import { ManageLizenzenController } from '@app_modules/ManageLizenzen/controller';
import { ViewDetailsModal } from '@app_modules/PaymentHistory/components';
import { ExportFilled } from '@app_assets/svg/CustomIcon';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

import './styles.css';

const { Text } = Typography;

function ManageLizenzenTable({ store, form }) {
  const { t } = useTranslation('common');

  const { getLizenzenInfos, handleChangeStatus, onChangePage, toggleAddOrUpdateLicenseModal } =
    ManageLizenzenController({
      store,
      form,
      t
    });

  const ExportFilledIcon = (props) => <Icon component={ExportFilled} {...props} />;

  // eslint-disable-next-line
  useEffect(getLizenzenInfos, []);

  const columns = [
    {
      title: t('User Data'),
      render: (invoiceData) => (
        <Space
          split={<Divider style={{ paddingTop: 10, paddingBottom: 10, margin: 0 }} />}
          direction="vertical"
        >
          <Space direction="vertical">
            <Text type="secondary">{t('NR')}</Text>
            <Text strong>{invoiceData.nr}</Text>
          </Space>

          <Space direction="vertical">
            <Text type="secondary">{t('Type of license').toLocaleUpperCase()}</Text>
            <Text strong>{invoiceData.art_der_lizenz}</Text>
          </Space>
          <Space direction="vertical">
            <Text type="secondary">{t('Quantity').toLocaleUpperCase()}</Text>
            <Text strong>{invoiceData.anzahl}</Text>
          </Space>
          <Space direction="vertical">
            <Text type="secondary">{t('Monthly').toLocaleUpperCase()}</Text>
            <Text strong>{invoiceData.monatspreis}</Text>
          </Space>
          <Space direction="vertical">
            <Text type="secondary">{t('Yearly').toLocaleUpperCase()}</Text>
            <Text strong>{invoiceData.jahrespreis}</Text>
          </Space>
          <Space direction="vertical">
            <Text type="secondary">{t('STATUS')}</Text>
            <Switch
              size="small"
              style={{ backgroundColor: invoiceData.active ? 'green' : 'red' }}
              defaultChecked={true}
              checkedChildren={t('active')}
              unCheckedChildren={t('inactive')}
              onChange={async (active) => await handleChangeStatus({ active, invoiceData })}
            />
            {/* <Text strong>{invoiceData.status}</Text> */}
          </Space>
        </Space>
      ),
      responsive: ['xs']
    },
    {
      title: t('NR').toUpperCase(),
      dataIndex: 'nr',
      align: 'center',
      render: (text) => <>{text}</>,
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      sorter: (a, b) => a.nr.localeCompare(b.nr)
    },
    {
      title: t('Type of License').toUpperCase(),
      dataIndex: 'art_der_lizenz',
      align: 'center',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      sorter: (a, b) => a.art_der_lizenz.localeCompare(b.art_der_lizenz),
      render: (art_der_lizenz) => <>{art_der_lizenz}</>
    },
    {
      title: t('Quantity').toUpperCase(),
      align: 'center',
      dataIndex: 'anzahl',
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (text) => <>{text}</>
    },
    {
      title: t('Monthly').toUpperCase(),
      dataIndex: 'monatspreis',
      align: 'center',

      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (text) => <>Fr.{text.toFixed(2)}</>
    },
    {
      title: t('Yearly').toUpperCase(),
      dataIndex: 'jahrespreis',
      align: 'center',
      width: 300,
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      responsive: ['sm', 'md', 'lg', 'xl'],
      render: (jahrespreis) => {
        const perMonth = jahrespreis / 12;
        return (
          <div style={{ padding: '0px 30px' }}>
            <div style={{ float: 'left' }}> Fr.{jahrespreis}.00 </div>{' '}
            <div style={{ float: 'right' }}>
              <span className="nti">Fr.{perMonth.toFixed(2)} / mo</span>
            </div>
          </div>
        );
      }
    },

    {
      title: t('STATUS').toUpperCase(),
      dataIndex: 'aktiv',
      align: 'center',
      responsive: ['xl'],
      shouldCellUpdate: (prev, next) => JSON.stringify(prev) !== JSON.stringify(next),
      render: (isActive, invoiceData) => {
        return (
          <Switch
            size="small"
            style={{ backgroundColor: isActive ? 'green' : 'red' }}
            defaultChecked={isActive ? true : false}
            checkedChildren={t('active')}
            unCheckedChildren={t('inactive')}
            onChange={async (aktiv) => await handleChangeStatus({ aktiv, invoiceData })}
          />
        );
      }
    }
  ];
  const licenseText = t('license');
  const ofText = t('of');
  return (
    <>
      <Table
        loading={store?.lizenzenInfo?.loading}
        className="exact-table shadow-sm bg-white p-3"
        size="small"
        columns={columns}
        dataSource={store?.lizenzenInfo?.state?.toJSON()}
        pagination={{
          position: ['bottomCenter'],
          hideOnSinglePage: true,
          pageSize: 10,
          responsive: true,
          onChange: onChangePage,
          showTotal: (total, range) => `${range[0]}-${range[1]} ${ofText} ${total} ${licenseText}`,
          // pageSizeOptions: [5, 10, 15, 20],
          showSizeChanger: false,
          total: store.lizenzenInfo.toJSON().total
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              const isUpdate = true;
              const DATA = record?.toJSON();
              toggleAddOrUpdateLicenseModal({ isUpdate, DATA });
            }
          };
        }}
      />

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ViewDetailsModal form={form} />
      </Suspense>
    </>
  );
}

export default inject('store')(observer(ManageLizenzenTable));
