import { message } from 'antd';

const GlobalController = ({ t }) => {
  const _showresultMessage = async (error, { successMessage }) => {
    console.log(
      '🚀 ~ file: GlobalController.js ~ line 5 ~ const_showresultMessage= ~ error',
      error
    );
    return new Promise((resolve, reject) => {
      if (error) {
        message.error(
          t(error?.response?.data?.error?.message) || 'Internal Error, Our developer is notified'
        );
        reject(false);
      } else {
        message.success(t(successMessage.message));
        resolve(true);
      }
    });
  };

  const search = (searchProperties, record) => (searchKeyword) => {
    return !!searchProperties
      ? searchProperties.length !== 0
        ? searchProperties.some((property) => {
            return record[property].toLowerCase().includes(searchKeyword.toLowerCase());
          })
        : true
      : true;
  };

  const getContactPerson = (User) => {
    const type = User?.employment;

    const Employment = {
      1: `${User?.company_name}`,
      2: `${User?.first_name} ${User?.last_name}`
    };
    return Employment[type];
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return { _showresultMessage, search, getContactPerson, capitalizeFirstLetter };
};

export default GlobalController;
