export const ANTD_MAX_COL = 24;
export const ANTD_HALF_COL = ANTD_MAX_COL / 2;
export const MOBILE_FRIENDLY_COL = {
  xs: { span: ANTD_HALF_COL },
  sm: { span: ANTD_HALF_COL },
  md: { span: ANTD_HALF_COL },
  xl: { span: ANTD_HALF_COL / 2 }
};
export const FOUR_GRID = {
  xs: { span: ANTD_MAX_COL },
  sm: { span: ANTD_HALF_COL },
  md: { span: ANTD_HALF_COL },
  xl: { span: ANTD_MAX_COL / 4 }
};
export const FOUR_GRID_ALL = {
  xs: { span: ANTD_MAX_COL / 4 },
  sm: { span: ANTD_MAX_COL / 4 },
  md: { span: ANTD_MAX_COL / 4 },
  xl: { span: ANTD_MAX_COL / 4 }
};

export const THREE_GRID = {
  xs: { span: ANTD_MAX_COL },
  sm: { span: ANTD_HALF_COL },
  md: { span: ANTD_HALF_COL },
  xl: { span: ANTD_MAX_COL / 3 }
};
export const TWO_GRID = {
  xs: { span: ANTD_MAX_COL },
  sm: { span: ANTD_HALF_COL },
  md: { span: ANTD_HALF_COL },
  xl: { span: ANTD_MAX_COL / 2 }
};
export const FULL_GRID = {
  xs: { span: ANTD_MAX_COL },
  sm: { span: ANTD_HALF_COL },
  md: { span: ANTD_HALF_COL },
  xl: { span: ANTD_MAX_COL }
};
