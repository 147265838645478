import { types } from 'mobx-state-tree';
import RecurringInvoiceModel from './RecurringInvoiceModel';
import StatusModel from './StatusModel';
import LicenseCodesModel from './LicenseCodesModel';

const SubscriptionModel = types
  .model('SubscriptionModel', {
    id: types.optional(types.number, 0),
    nr: types.optional(types.string, ''),
    key: types.optional(types.number, 0),
    license_id: types.optional(types.number, 0),
    user_id: types.optional(types.string, ''),
    number: types.optional(types.number, 0),
    status_id: types.optional(types.number, 0),
    purchase_date: types.optional(types.string, ''),
    end_date: types.optional(types.string, ''),
    cancellation_date: types.optional(types.maybeNull(types.string), ''),
    payment_type: types.optional(types.number, 1),
    active: types.optional(types.boolean, true),
    RecurringInvoices: types.optional(types.maybeNull(RecurringInvoiceModel), {}),
    LicenseCodes: types.optional(types.array(LicenseCodesModel), []),
    Status: types.optional(StatusModel, {})
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default SubscriptionModel;
