import { types } from 'mobx-state-tree';

const StatusModel = types
  .model('StatusModel', {
    id: types.optional(types.number, 0),
    key: types.optional(types.number, 0),
    status: types.optional(types.string, ''),
    active: types.optional(types.boolean, true)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default StatusModel;
