import { types, cast, flow } from 'mobx-state-tree';
import AddSubscriptionModel from './AddSubscriptionModel';

import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
//AXIOS
import axios from 'axios';
//DAYJS
import dayjs from 'dayjs';
//REST API
const API_PATH = '/api/lizenzen';
const API_PATH_RECURRING_INVOICE = '/api/recurring-invoice';
const SENDMAIL = 'sendmail-add-subscription';

export default types
  .model('AddSubscriptionState', {
    state: types.optional(types.array(AddSubscriptionModel), []),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),
    InitializeAddSubscription(values) {
      try {
        self.isCreating = true;
        self.state = cast(values);
        self.isCreating = false;

        return [{ message: 'Created!', values }, null];
      } catch (error) {
        self.isCreating = false;
        return [null, error];
      }
    },
    setSubscription(record) {
      self.singleState = Object.assign(record);
    },
    UpdateLocal({ id, keyToBeUpdate }) {
      const keyName = keyToBeUpdate.name;
      const indexAddSubscription = self.state.findIndex((row) => row.id === id);
      const newData = {
        ...self.state[indexAddSubscription],
        [keyName]: keyToBeUpdate.value
      };
      try {
        self.state.splice(
          self.state.findIndex((row) => row.id === id),
          1,
          newData
        );

        return [{ message: 'Updated!' }, null];
      } catch (error) {
        return [null, error];
      }
    },
    createRecurringInvoice: flow(function* (values, t, store) {
      try {
        self.isCreating = true;
        const { data } = yield axios.post(API_PATH_RECURRING_INVOICE, values);
        const dateNow = dayjs();
        const invoice_date = dayjs(values?.invoice_date).format('LL'); // 'December 31, 2021'
        const date_subscribe = dayjs(dateNow).format('LL');
        let paymentType = '';
        if (data.frequency === 1) paymentType = t('Monthly');
        if (data.frequency === 2) paymentType = t('Yearly');
        const mailContent = {
          art_der_lizenz: values.art_der_lizenz,
          nr: data.nr,
          invoice_date,
          date_subscribe,
          number: values.number,
          paymentType,
          user_id: values.user_id,
          email: store.login.email
        };
        // const { data: MailData } = yield axios.post(
        //   `${API_PATH_RECURRING_INVOICE}/${SENDMAIL}`,
        //   mailContent
        // );

        self.state.push(data);
        self.isCreating = false;
        return [{ message: 'Created!', data }, null];
      } catch (error) {
        console.log(
          '🚀 ~ file: AddSubscriptionState.js ~ line 68 ~ createRecurringInvoice:flow ~ error',
          error
        );
        self.isCreating = false;
        return [null, error];
      }
    })
  }))
  .views((self) => ({}));
