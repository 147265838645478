import { types } from 'mobx-state-tree';

const RecurringInvoiceUtilities = types
  .model('recurringInvoiceUtilities', {
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), [
      'id',
      'license_id',
      'user_id',
      'invoice_date',
      'frequency',
      'active'
    ]),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.number, 0),
    isViewDetailsVisible: types.optional(types.boolean, false),
    isExport: types.optional(types.boolean, false),
    isViewProfileVisible: types.optional(types.boolean, false),
    pageNumber: types.optional(types.number, 1)
  })
  .views((self) => ({}))
  .actions((self) => ({
    toggleViewDetailsModal() {
      self.isViewDetailsVisible = !self.isViewDetailsVisible;
    },
    toggleViewProfileModal() {
      self.isViewProfileVisible = !self.isViewProfileVisible;
    },
    setPageNumber(pageNumber) {
      self.pageNumber = pageNumber;
    },
    setSearch(searchValue) {
      self.search = searchValue;
    }
  }));

export default RecurringInvoiceUtilities;
