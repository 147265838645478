import React, { Suspense } from 'react';
import { Row, Modal, Button, Col, Table, Typography, Input, Dropdown, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

//DAYJS
import dayjs from 'dayjs';
//Ant D
import { default as Icon } from '@ant-design/icons';
/** APP Imports */
import { ExportFilled } from '@app_assets/svg/CustomIcon';
import { PaymentHistoryController } from '@app_modules/PaymentHistory/controller';
import { RecurringInvoiceController } from '@app_modules/RecurringInvoice/controller';
import { CompanyUserDetails, PrivateUserDetails } from '@app_modules/RecurringInvoice/components';
/**CORE IMPORTS */
import { ExactText, FallBackLoaders, ExactTitle } from '@core_common/components';

const { Title, Text } = Typography;

function ViewProfileModal({ store, form }) {
  const { t } = useTranslation('common');

  const { toggleViewProfileModal } = RecurringInvoiceController({
    store,
    t
  });

  const { User } = store?.recurringInvoices?.singleState || {};
  const { isViewProfileVisible } = store?.recurringInvoiceUtilities;

  const UserDetails = {
    1: <CompanyUserDetails />,
    2: <PrivateUserDetails />
  };

  return (
    <Modal
      title={<ExactText text={t('View User Details')} />}
      visible={isViewProfileVisible}
      footer={null}
      className="exact-modal"
      style={{ top: 20 }}
      onCancel={() => toggleViewProfileModal()}
    >
      {UserDetails[User.employment]}
    </Modal>
  );
}

export default inject('store')(observer(ViewProfileModal));
