import { types } from 'mobx-state-tree';

import UserTypesModel from '@core_modules/UserManagement/model/UserTypesModel';

const invoiceUtilities = types
  .model('invoiceUtilities', {
    isViewDetailsVisible: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), ['invoice_nr', 'invoice_date']),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({
    toggleViewDetailsModal() {
      self.isViewDetailsVisible = !self.isViewDetailsVisible;
    },
    setSearch(searchValue) {
      self.search = searchValue;
    },
    setUpdateId(id) {
      self.updateId = id;
    }
  }));

export default invoiceUtilities;
