import { types } from 'mobx-state-tree';

const SubscriptionModel = types
  .model('SubscriptionModel', {
    id: types.optional(types.string, ''),
    nr: types.optional(types.number, 0),
    key: types.optional(types.string, ''),
    anzahl: types.optional(types.number, 1),
    monatspreis: types.optional(types.number, 0),
    jahrespreis: types.optional(types.number, 0),
    art_der_lizenz: types.optional(types.number, 0),
    aktiv: types.optional(types.boolean, true),
    zeitstempel: types.optional(types.string, ''),
    updatedAt: types.optional(types.string, ''),
    end_date: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default SubscriptionModel;
