import React from 'react';
import { Col, Row, Space, Input, Typography } from 'antd';
/**CORE IMPORTS */
import { TWO_GRID } from '@core_common/antdhelpers/constants';

// TRANSLATION
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

const { Text } = Typography;

function PrivateUserDetails({ store }) {
  const { t } = useTranslation('common');
  const { User } = store.recurringInvoices.singleState || {};
  return (
    <div>
      <Row gutter={[16, 0]}>
        <Col {...TWO_GRID}>
          <Text> {t('First Name')} </Text>
          <Input value={User.first_name} className="w-100 mt-top-1vh" />
        </Col>
        <Col {...TWO_GRID}>
          <Text>{t('Last Name')}</Text>
          <Input value={User.last_name} className="w-100 mt-top-1vh" />
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 0]}>
        <Col {...TWO_GRID}>
          <div>{t('Address')}</div>

          <Input value={User.additional_address} className="w-100 mt-top-1vh" />
        </Col>
        <Col {...TWO_GRID}>
          <div>{t('Postal Code')}</div>

          <Input value={User.zip_code} className="w-100 mt-top-1vh" />
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 0]}>
        <Col {...TWO_GRID}>
          <div>{t('Email Address')}</div>

          <Input value={User.email} className="w-100 mt-top-1vh" />
        </Col>
        <Col {...TWO_GRID}>
          <div>{t('Phone Number')}</div>

          <Input value={User.phone_number} className="w-100 mt-top-1vh" />
        </Col>
      </Row>
      <br />
    </div>
  );
}
export default inject('store')(observer(PrivateUserDetails));
