import { cast, types, flow } from 'mobx-state-tree';
import axios from 'axios';

import UserTypesModel from '@core_modules/UserManagement/model/UserTypesModel';
import CountryData from '@core_data/countries/Countries.json';

import LicenseCodesModel from './LicenseCodesModel';
import SubscriptionModel from './SubscriptionModel';

const API_PATH = '/api/users';

const subscriptionUtilities = types
  .model('subscriptionUtilities', {
    isAddOrUpdateSubscriptionVisible: types.optional(types.boolean, false),
    isUpdateSubscriptionVisible: types.optional(types.boolean, false),
    subscription: types.optional(SubscriptionModel, {}),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), ['nr', 'art_der_lizenz', 'anzahl']),
    userTypes: types.optional(types.array(UserTypesModel), []),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.string, ''),
    nr: types.optional(types.string, ''),
    searchKeyword: types.optional(types.string, ''),
    cities: types.optional(types.array(types.string), []),
    LicenseCodes: types.optional(types.array(LicenseCodesModel), [])
  })
  .views((self) => ({}))
  .actions((self) => ({
    setSearchKeyword(keyword) {
      self.searchKeyword = keyword;
    },
    setLicenseCodes({ LicenseCodes }) {
      self.LicenseCodes = cast(LicenseCodes);
    },
    setNR(nr) {
      self.nr = nr;
    },
    setisAddOrUpdateSubscriptionVisible(TrueOrFalse) {
      self.isAddOrUpdateSubscriptionVisible = TrueOrFalse;
    },
    toggleAddOrUpdateSubscriptionModal() {
      self.isAddOrUpdateSubscriptionVisible = !self.isAddOrUpdateSubscriptionVisible;
    },
    toggleUpdateSubscriptionModal(record) {
      self.isUpdateSubscriptionVisible = !self.isUpdateSubscriptionVisible;
    },
    setUpdateSubscriptionModal(state) {
      self.isUpdateSubscriptionVisible = state;
    },

    setSubscription(record) {
      console.log(
        '🚀 ~ file: subscriptionUtilities.js ~ line 52 ~ setSubscription ~ record',
        record
      );
      self.subscription = Object.assign(record);
    },
    setCities(selectedCountry) {
      if (selectedCountry) {
        const country = CountryData.find((row) => row.country === selectedCountry);
        return (self.cities = cast(country.cities));
      }
      if (!selectedCountry) {
        self.cities = [];
      }
    },

    setUpdateId(id) {
      self.updateId = id;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },

    FETCH_USER_TYPES: flow(function* () {
      const { data } = yield axios.get(`${API_PATH}/types`);
      self.userTypes = cast(data);
    })
  }));

export default subscriptionUtilities;
